<script>
  import Button, { Label } from "@smui/button";
  import { getContext } from "svelte";

  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { needOpenRoleChangeDialog } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 切替え可能なEC事業者名 @type {string} */
  export const switchableEcName = (() => {
    let switchableEcName = "";
    for (const ec of userContext.loginUser.switchableCompanies) {
      if (ec.name === userContext.getCurrentCompanyName()) {
        continue;
      }
      if (switchableEcName) {
        switchableEcName += ",";
      }
      switchableEcName += ec.name;
    }
    return switchableEcName;
  })();
</script>

<div class="ecSwitchableInfo">
  <p class="material-icons md-24">info</p>
  <p class="text">
    {userContext.getCurrentCompanyName()} ユーザーでログインしています。<br />
    <slot name="description" />
  </p>
  <Button
    style="height: 40px; width: 180px; border: 1px solid #2b81d6; font-size: smaller; "
    touch
    on:click={() => {
      needOpenRoleChangeDialog.set(true);
    }}
  >
    <Label>ユーザーを切り替える</Label></Button
  >
</div>

<style lang="scss">
  .ecSwitchableInfo {
    display: flex;
    align-items: center;
    width: 782px;
    padding: 5px 3px;
    background-color: #f3f8fc;
    font-weight: 700;
    color: #242424;
    margin: 10px 0 0 0;
    border-radius: 5px;

    .material-icons {
      color: #2b81d6;
      margin: 0 12px;
    }

    .text {
      font-size: 13px;
      line-height: 1.4;
      color: #242424;
      min-width: 545px;
    }
  }
</style>
