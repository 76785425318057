<script>
  import Button from "@smui/button";
  import Dialog, { Content, Title } from "@smui/dialog";
  import { createEventDispatcher, getContext } from "svelte";

  import CloseIcon from "~/components/icons/close.svelte";
  import { CONTEXT_KEY_USER, ROLES_MAP } from "~/libs/constants";

  /** @type {boolean} */
  export let open;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let dispatch = createEventDispatcher();
</script>

<Dialog
  bind:open
  aria-labelledby="profile-dialog-title"
  aria-describedby="profile-dialog-content"
  style="margin-top: 30px; max-height: 90%;"
  surface$style="max-width: 95vw;"
>
  <Title
    id="profile-dialog-title"
    style="
  border-bottom: solid;
  border-width: 2px;
  border-color: rgb(220, 220, 220);
  display: flex;
  padding-top: 20px;"
  >
    <p>プロフィール</p>
    <div class="closeButtonArea">
      <button
        class="closeButton"
        on:click={() => {
          open = false;
        }}
      >
        <CloseIcon /></button
      >
    </div>
  </Title>

  <Content id="profile-dialog-content" style="padding-top: 20px;">
    <div class="item">
      <div class="itemName">会社名</div>
      <div class="itemLine">
        <p>{userContext.loginUser?.companyName}</p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">ユーザー種別</div>
      <div class="itemLine">
        <p>
          {userContext.loginUser?.roles
            .map((role) => ROLES_MAP.get(role))
            .join("、")}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">ユーザーID</div>
      <div class="itemLine">
        <p>{userContext.loginUser?.username}</p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">表示名</div>
      <div class="itemLine">
        <p>{userContext.loginUser?.displayName}</p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">メールアドレス</div>
      <div class="itemLine">
        <p style="word-break: break-word;">
          {userContext.loginUser?.emailAddress ?? ""}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="itemName">パスワード</div>
      <div class="itemLine">
        <p>設定済</p>
        <div class="button">
          <Button
            style="background-color: #6200EE; color: #fff;"
            on:click={() => {
              dispatch("changePassword");
              open = false;
            }}>変更</Button
          >
        </div>
      </div>
    </div>
  </Content>
</Dialog>

<style lang="scss">
  .closeButtonArea {
    margin: 0 0 0 auto;

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    :global(.svg-icons-close) {
      width: 30px;
      height: 30px;
    }
  }
  .item {
    display: flex;
    position: relative;
    min-width: 450px;
    margin: 3px 20px;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 200px;
    height: 30px;
    margin: 0 30px 25px 0;
    padding-top: 8px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .itemLine {
    width: 100%;
    height: 37px;
    display: flex;
    border-bottom: solid;
    border-width: 2px;
    border-color: rgb(220, 220, 220);
  }
  .itemLine > p {
    color: #333;
    padding-top: 8px;
  }
  .button {
    margin: 0 0 0 auto;
  }
</style>
