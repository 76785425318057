<script>
  import Button from "@smui/button";
  import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import Radio from "@smui/radio";
  import Switch from "@smui/switch";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import backendApi, { ErrorResponseException } from "~/libs/backendApi";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { toast } from "~/libs/toast";
  import { formatUtcToJst } from "~/libs/utils";

  /**
   *  出荷単位一覧を格納する配列
   * @type {import("~/libs/backendApi").SearchShippingUnitsResponse}
   */
  export let shippingUnits;
  /**
   * ラジオボタンで選択した出荷単位のIDを格納する変数
   * @type {string}
   */
  export let selectedShippingUnitId;
  /**
   * EC企業ID
   * @type {number}
   */
  export let ecId;
  /**
   * ダイアログを閉じるハンドラ
   * @type {function(): void}
   */
  export let dialogCloseHandler;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let open;
  /**
   * 発行誤りの登録ができる状態かどうか。
   * @type {boolean} true: 発行誤りの登録ができる状態。false: 発行誤りの登録ができない状態。
   */
  let isRegisterCancel = false;
  /**
   * 輸送中の荷物を含むなど、発行誤りの状態にできない出荷番号のIDを格納する配列。
   * @type {string[]}
   */
  let deliveredShippingIds = [];

  /**
   * 発行元に関するパラメータを受け取り、理解しやすい日本語に置き換える。
   * S:システム間連携による自動発行、M:管理機能による手動発行
   * @param {string} releasedBy 発行元
   * @returns {string}
   */
  function replaceReleasedBy(releasedBy) {
    switch (releasedBy) {
      case "M":
        return "管理機能";
      case "S":
        return "システム";
      default:
        return "";
    }
  }

  /**
   * 発行誤りの登録ができる状態かどうかを切り替える。
   */
  function toggleRegisterCancel() {
    isRegisterCancel = !isRegisterCancel;
  }

  /**
   * ラジオボタンの選択状態を解除する。
   */
  function removeFilters() {
    selectedShippingUnitId = "";
  }

  /**
   * 発行誤りのトグルをONにした出荷番号の発行誤り状態を切り替える。(ECサイト管理者のみ使用できる)
   * @param {import("~/libs/backendApi").SearchedShippingUnit} item
   */
  const toggleDisabled = async (item) => {
    // 対象の出荷番号をDBで扱っている形式に組み立てる。
    const disabled = !item.disabled;
    const shippingUnitId = item.releasedBy + "-" + ecId + "-" + item.releasedAt;

    try {
      let body = {
        disabled: disabled,
      };
      await backendApi.disableShippingUnit(body, shippingUnitId);
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "alreadyInTransit"
      ) {
        toast.error($_("errors.alreadyInTransit.message"));
        if (!deliveredShippingIds.includes(item.releasedAt)) {
          item.disabled = false;
          deliveredShippingIds.push(item.releasedAt);
          deliveredShippingIds = [...deliveredShippingIds];
        }
      } else {
        showErrorToast(error);
      }
    }
  };

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error instanceof HTTPError && error.response?.status == 401) {
      toast.error($_("errors.updateUnauthorized.message"));
    } else if (error instanceof HTTPError && error.response?.status == 403) {
      toast.error($_("errors.updateForbidden.message"));
    } else {
      console.error(error);
      toast.error($_("errors.updateDefaultMessage.message"));
    }
  }

  open = true;
</script>

<div class="dataTableWrapper">
  <Dialog
    bind:open
    aria-labelledby="detail-dialog-title"
    aria-describedby="detail-dialog-content"
    on:SMUIDialog:closed={dialogCloseHandler}
    style="margin-top: 30px; max-height: 90%; z-index: 100;"
  >
    <div class="titleArea">
      <Title id="detail-dialog-title">データの絞り込み</Title>
      <Actions>
        <Button class="closeButton"
          ><span class="material-icons closeButton">close</span></Button
        >
      </Actions>
    </div>
    <Content id="detail-dialog-content">
      {#if shippingUnits}
        <p>選択した期間内に発行したデータを絞り込むことができます。</p>
        {#if isRegisterCancel}
          <p>
            発行誤りをオンにすると、誤って発行したデータを検索結果に表示<br
            />されないようにできます。
          </p>
        {/if}
        <DataTable
          stickyHeader
          table$aria-label="filter list"
          style="width: 100%; margin-top: 10px;"
        >
          <Head>
            <Row style="height: 40px;">
              <Cell></Cell>
              <Cell>発行日時</Cell>
              <Cell>件数</Cell>
              <Cell>発行元</Cell>
              {#if isRegisterCancel}
                <Cell>発行誤り</Cell>
              {/if}
            </Row>
          </Head>
          <Body>
            {#each shippingUnits as item (item.releasedAt)}
              <Row
                style="height: 40px; background-color: {item.disabled
                  ? '#ddd;'
                  : `${
                      selectedShippingUnitId ===
                      item.releasedBy +
                        '-' +
                        String(ecId) +
                        '-' +
                        item.releasedAt
                        ? '#1976d222;'
                        : ''
                    }`}"
              >
                {#if item.disabled}
                  <Cell>&nbsp;－</Cell>
                {:else}
                  <Cell>
                    <Radio
                      bind:group={selectedShippingUnitId}
                      value={item.releasedBy +
                        "-" +
                        String(ecId) +
                        "-" +
                        item.releasedAt}
                      on:change={() => {
                        setTimeout(() => {
                          open = false;
                        }, 300);
                      }}
                      style="transform:scale(1.4);"
                    />
                  </Cell>
                {/if}
                <Cell
                  >{formatUtcToJst(item.releasedAt, "yyyy/MM/dd H:mm", {
                    locale: localeJa,
                  })}</Cell
                >
                <Cell>{item.numberOfShipments}件</Cell>
                <Cell>{replaceReleasedBy(item.releasedBy)}</Cell>
                {#if isRegisterCancel}
                  <Cell>
                    {#if deliveredShippingIds.includes(item.releasedAt)}
                      <Switch
                        checked={false}
                        on:click={toggleDisabled(item)}
                        icons={false}
                        disabled
                      />
                    {:else}
                      <Switch
                        bind:checked={item.disabled}
                        on:click={toggleDisabled(item)}
                        icons={false}
                      />
                    {/if}
                  </Cell>
                {/if}
              </Row>
            {/each}
          </Body>
        </DataTable>
        <div class="subActionArea">
          {#if selectedShippingUnitId}
            <Button
              style="position: absolute; top: 0; left: 0; background-color: #1976d2; color: #fff; margin-top: 10px;"
              on:click={removeFilters}>絞り込みを解除する</Button
            >
          {/if}
          {#if userContext.hasEcAdminRole() && !isRegisterCancel}
            <div class="registerCancelLinkContainer">
              <button on:click={toggleRegisterCancel} class="registerCancelLink"
                >誤って発行したデータが含まれている場合</button
              >
            </div>
          {/if}
        </div>
      {:else}
        <p>選択した期間内に発行したデータがありません。</p>
      {/if}
    </Content>
  </Dialog>
</div>

<style lang="scss">
  .dataTableWrapper {
    :global(.mdc-data-table) {
      max-height: 400px;
      overflow-y: scroll;
    }
    :global(.mdc-data-table__header-cell) {
      vertical-align: middle;
      font-weight: bolder;
    }
    :global(.mdc-data-table__cell) {
      vertical-align: middle;
    }
    :global(.mdc-radio__native-control) {
      margin: 0 0 0 6px;
    }
    :global(.mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
      background-color: rgba(25, 118, 210, 0.04);
    }
  }
  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .closeButton {
    color: #333;
  }
  .subActionArea {
    height: 40px;
    position: relative;
  }
  .registerCancelLinkContainer {
    position: absolute;
    top: 6px;
    right: 0;
    text-align: right;
  }
  .registerCancelLink {
    color: #1976d2;
    background-color: #fff;
    font-size: 14px;
    text-decoration: underline;
    border: none;
  }
  .registerCancelLink:hover {
    color: #1976d290;
  }
</style>
