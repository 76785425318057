<script context="module">
  export const isNumber = (value) => typeof value === "number";
</script>

<script>
  export let filterValue;
  export let values;
  let selectableValues = [];
  (() => {
    selectableValues = $values;
    // 本日と昨日は常に選択可能
    selectableValues.push(0);
    selectableValues.push(1);
    selectableValues = Array.from(new Set($values)).sort((a, b) => a - b);
    filterValue.set([0, 1]);
  })();

  function getDisplayValue(value) {
    return value === Number.MAX_VALUE
      ? "実績なし"
      : value === 0
        ? "本日"
        : value === 1
          ? "昨日"
          : `${value}日前`;
  }
</script>

<div>
  <select class="from" bind:value={$filterValue[1]} on:click|stopPropagation>
    {#each selectableValues as value}
      <option {value}>{getDisplayValue(value)}</option>
    {/each}
  </select>
  ～
  <select class="to" bind:value={$filterValue[0]} on:click|stopPropagation>
    {#each selectableValues as value}
      <option {value}>{getDisplayValue(value)}</option>
    {/each}
  </select>
</div>

<style>
  div {
    display: flex;
  }

  select {
    font-size: 12px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;

    &.from {
      margin-right: 2px;
    }

    &.to {
      margin-left: 2px;
    }
  }
</style>
