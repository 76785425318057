<script>
  import { onDestroy, onMount } from "svelte";

  export let rowAttrs;
  export let root = undefined;

  let observer;

  let inView = false;
  let thisElement;

  const handler = (e) => {
    // 場合によっては複数のentryが返ってくることがあり、意図せずinViewがfalseになることがある
    // 1つでもisIntersectingがtrueならinViewをtrueにする
    let isIntersecting = false;
    e.forEach((entry) => {
      isIntersecting = isIntersecting || entry.isIntersecting;
    });
    inView = isIntersecting;
  };

  onMount(() => {
    observer = new IntersectionObserver(handler, { root });
    observer.observe(thisElement);
  });
  onDestroy(() => {
    observer.unobserve(thisElement);
    observer.disconnect();
  });
</script>

<tr class="mdc-data-table__row" bind:this={thisElement} {...rowAttrs}>
  <slot {inView} />
</tr>
