export const textIncludeFilter = ({ filterValue, value }) => {
  return (
    String(value).toLowerCase().indexOf(String(filterValue).toLowerCase()) !==
    -1
  );
};

export const matchFilter = ({ filterValue, value }) => {
  if (filterValue === undefined) return true;
  return filterValue === value;
};

export const numberRangeFilter = ({ filterValue, value }) => {
  if (!Array.isArray(filterValue) || typeof value !== "number") return true;
  const [min, max] = filterValue;
  if (min === null && max === null) return true;
  if (min === null) return value <= max;
  if (max === null) return min <= value;

  return min <= value && value <= max;
};
