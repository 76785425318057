import { ja as localeJa } from "date-fns/locale";

import { formatStringDate, getCurrentDateTimeOnJst } from "~/libs/utils";

/**
 * 中継配送センターIDと荷物件数のリストを受け取り、表示用のデータに変換する
 * @param {Map<number, object>} centerMap
 * @param {Array<{transportDestivationId: number, packageNum: number}>} transportDestivationIdAndPackageNumList
 * @returns {string}
 */
export function getCenterNameAndPackageNumString(
  centerMap,
  transportDestivationIdAndPackageNumList,
) {
  let result = "";
  if (!centerMap || !transportDestivationIdAndPackageNumList) {
    return result;
  }
  for (const item of transportDestivationIdAndPackageNumList) {
    if (result !== "") {
      result += "<br />";
    }
    result += `${centerMap.get(item.transportDestivationId)?.name ?? "センター名取得失敗"} (${item.packageNum}個)`;
  }
  return result === "" ? "該当なし" : result;
}

/**
 * 中継配送センターIDのリストを受け取り、表示用のデータに変換する
 * @param {Map<number, object>} centerMap
 * @param {Array<number>} centerIdList
 * @returns {string}
 */
export function getCenterNameString(centerMap, centerIdList) {
  let result = "";
  if (!centerMap || !centerIdList) {
    return result;
  }
  for (const centerId of centerIdList) {
    if (result !== "") {
      result += "<br />";
    }
    result += centerMap.get(centerId)?.name ?? "センター名取得失敗";
  }
  return result === "" ? "該当なし" : result;
}

/**
 * 最終業務日時をもとに当日までの日数を返す。
 * 業務実績が無い場合はMAX_VALUEを返す。
 * @param {Array<{
 *   startAt: string,
 *   endAt: string,
 * }>} lastWorkedAt 最終業務日時
 * @returns {number} 当日までの日数
 */
export function getDaysFromLastWorkedAt(lastWorkedAt) {
  // 業務実績が無い場合はMAX_VALUEを返す
  if (!lastWorkedAt) {
    return Number.MAX_VALUE;
  }

  // 終了時間が無い場合は業務中のため当日(0)
  const lastTimeset = lastWorkedAt[lastWorkedAt.length - 1];
  if (lastTimeset.endAt === undefined) {
    return 0;
  }

  // 日付が今日と一致する場合は0
  const lastWorkedAtDate = new Date(lastTimeset.endAt);
  lastWorkedAtDate.setHours(0, 0, 0, 0);
  const today = getCurrentDateTimeOnJst();
  today.setHours(0, 0, 0, 0);
  if (lastWorkedAtDate.getTime() === today.getTime()) {
    return 0;
  }

  // 一致しない場合は日数を計算
  const diffTime = today.getTime() - lastWorkedAtDate.getTime();
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * 業務実績の開始時間と終了時間を文字列にして返す。
 * @param {Array<{
 *   startAt: string,
 *   endAt: string,
 * }>} workTimes 最終業務日時
 * @returns {string} 業務時間の文字列
 */
export function getWorkedAtString(workTimes) {
  if (!workTimes) {
    return "";
  }
  return workTimes
    .map((timeset) => {
      return `${formatStringDate(timeset.startAt, "MM/dd(E) H:mm", { locale: localeJa })} ～ ${timeset.endAt ? formatStringDate(timeset.endAt, "MM/dd(E) H:mm", { locale: localeJa }) : ""}`;
    })
    .join("<br/>");
}
