<script>
  import Button from "@smui/button";
  import { differenceInDays, startOfDay } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext, onMount } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import {
    addColumnFilters,
    addHiddenColumns,
    addPagination,
    addSortBy,
  } from "svelte-headless-table/plugins";
  import { _ } from "svelte-i18n";

  import SelectFilter from "~/components/SelectFilter.svelte";
  import TextFilter from "~/components/TextFilter.svelte";
  import { CONTEXT_KEY_APP, CONTEXT_KEY_USER } from "~/libs/constants";
  import desiredDateTime from "~/libs/desiredDateTime";
  import { matchFilter, textIncludeFilter } from "~/libs/filters";
  import { searchResultData } from "~/libs/stores";
  import {
    displayPostcodeFormat,
    formatStringDate,
    formatUtcToJst,
  } from "~/libs/utils";
  import SearchResultDetailButton from "~/pages/Search/SearchResultDetailButton.svelte";
  import SearchResultRow from "~/pages/Search/SearchResultRow.svelte";
  import SearchResultTableStatus from "~/pages/Search/SearchResultTableStatus.svelte";
  import SearchResultTableStatusIcon from "~/pages/Search/SearchResultTableStatusIcon.svelte";

  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} */
  export let results;
  (() => {
    searchResultData.set(results);
  })();

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  /** @type {number} */
  export let filterResultsNum;

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const table = createTable(searchResultData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
    hide: addHiddenColumns(),
    page: addPagination({ initialPageSize: 1000 }),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "",
          id: "statusIcon",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(SearchResultTableStatusIcon, {
              record: dataCell.value,
            }),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "",
          id: "detailButton",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(SearchResultDetailButton, {
              record: dataCell.value,
            }),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "送り状番号",
          id: "trackingNumber",
          accessor: (item) => item.trackingNumber ?? "",
        }),
        table.column({
          header: "配送／返品ステータス",
          id: "status",
          accessor: (item) => item.shippingAndReturnStatus ?? "",
          plugins: {
            filter: {
              fn: matchFilter,
              render: ({ filterValue, preFilteredValues }) =>
                createRender(SelectFilter, {
                  filterValue,
                  preFilteredValues,
                  results,
                  columnId: "status",
                }),
            },
          },
        }),
      ];
      if (!userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "EC事業者名",
            id: "ecCompanyName",
            accessor: (item) => item.customerName ?? "",
          }),
        );
      }
      columns.push(
        table.column({
          header: "注文番号",
          id: "customerOrderId",
          accessor: (item) => item.customerOrderId ?? "",
        }),
        table.column({
          header: "届け先郵便番号",
          id: "receiverPostcode",
          accessor: (item) =>
            displayPostcodeFormat(item.receiverPostcode) ?? "",
        }),
        table.column({
          header: "届け先住所1",
          id: "receiverAddress1",
          accessor: (item) => item.receiverAddress1 ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "届け先住所2",
          id: "receiverAddress2",
          accessor: (item) => item.receiverAddress2 ?? "",
        }),
        table.column({
          header: "届け先住所（訂正後）",
          id: "correctedReceiverAddress",
          accessor: (item) => item.correctedReceiverAddress ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "届け先名",
          id: "receiverName",
          accessor: (item) => item.receiverName ?? "",
        }),
        table.column({
          header: "個数",
          id: "quantity",
          accessor: (item) => item.numberOfPackages ?? "1",
        }),
        table.column({
          header: "発行日時",
          id: "releasedAt",
          accessor: (item) =>
            formatUtcToJst(item.releasedAt, "yyyy/MM/dd(E) HH:mm", {
              locale: localeJa,
            }) ?? "",
        }),
      );
      if (!userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "荷受け登録場所",
            id: "relayLocationId",
            accessor: (item) =>
              Number.isInteger(item.inTransitLocationId)
                ? (centersMap.get(item.inTransitLocationId)?.name ?? "該当なし")
                : "未登録",
          }),
          table.column({
            header: "配達希望日時",
            id: "desiredDate",
            accessor: (
              /** @type {import("~/libs/backendApi").SearchedShipment} */ item,
            ) => {
              const dateAndTimeFrame = desiredDateTime
                .resolve(item)
                .find(
                  (e) => e.timeFrame !== desiredDateTime.UNRECEIVABLE_TIME_SLOT,
                );
              if (dateAndTimeFrame) {
                // 日付部分のフォーマット
                let formattedDate = "日付指定なし";
                if (dateAndTimeFrame.date) {
                  formattedDate = formatStringDate(
                    dateAndTimeFrame.date,
                    "yyyy/MM/dd(E)",
                    {
                      locale: localeJa,
                    },
                  );
                }
                // 時間部分のフォーマット
                let formattedTime = "時間指定なし";
                if (/^\d{4}$/.test(dateAndTimeFrame.timeFrame)) {
                  const timeRangeBegin = `${dateAndTimeFrame.timeFrame.substring(0, 2)}:00`;
                  const timeRangeEnd = `${dateAndTimeFrame.timeFrame.substring(2)}:00`;
                  formattedTime = `${timeRangeBegin}〜${timeRangeEnd != "00:00" ? timeRangeEnd : ""}`;
                }
                return `${formattedDate} ${formattedTime}`;
              } else {
                if (
                  item.redeliveryContext?.redeliveryDatetimeSpecMethod == null
                ) {
                  return "日時指定なし";
                } else {
                  return "設定依頼中";
                }
              }
            },
          }),
          table.column({
            header: "集荷日時（経過日数）",
            id: "inTransitAt",
            accessor: (item) => item.inTransitAt ?? "未登録",
          }),
          table.column({
            header: "配達試行回数",
            id: "numberOfDeliveryAttempts",
            accessor: (item) => item.numberOfDeliveryAttempts ?? "0",
          }),
          table.column({
            header: "代引金額",
            id: "cashOnDeliveryAmount",
            accessor: (item) => item.cashOnDeliveryAmount ?? 0,
          }),
          table.column({
            header: "宅配ドライバー名",
            id: "driverDisplayName",
            accessor: (item) => item.driverDisplayName ?? "未登録",
            plugins: {
              filter: {
                fn: matchFilter,
                render: ({ filterValue, preFilteredValues }) =>
                  createRender(SelectFilter, {
                    filterValue,
                    preFilteredValues,
                    results,
                    columnId: "driverDisplayName",
                  }),
              },
            },
          }),
        );
      }
      if (userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "代引金額",
            id: "cashOnDeliveryAmount",
            accessor: (item) => item.cashOnDeliveryAmount ?? 0,
          }),
        );
      }
      if (userContext.hasShippingPartnerAdminRole()) {
        columns.push(
          table.column({
            header: "宅配事業者管理者専用メモ欄",
            id: "shippingPartnerInternalMessage",
            accessor: (item) => item.shippingPartnerInternalMessage ?? "",
            plugins: {
              filter: {
                fn: textIncludeFilter,
                initialFilterValue: "",
                render: ({ filterValue, values }) =>
                  createRender(TextFilter, {
                    filterValue,
                    values,
                  }),
              },
            },
          }),
        );
      }
      return columns;
    })(),
  );

  const {
    flatColumns,
    headerRows,
    rows,
    tableAttrs,
    tableHeadAttrs,
    tableBodyAttrs,
    pluginStates,
    pageRows,
  } = table.createViewModel(columns);
  const { hiddenColumnIds } = pluginStates.hide;
  const { pageIndex, hasPreviousPage, hasNextPage, pageCount } =
    pluginStates.page;
  const ids = flatColumns.map((c) => c.id);

  /** @type {Array<string>} 表示・非表示制御が可能なカラムIDの配列 */
  const displayControlIds = [...ids].filter(
    (id) => id !== "statusIcon" && id !== "detailButton",
  );

  /** @type {Map} 表示カラムの制御用Map（反転処理用※headlessTableの仕様では正の場合に非表示になるが、偽の場合に非表示にするために用いる） */
  const displayControlHideForId = (() => {
    let hideForId = new Map();
    displayControlIds.forEach((id) => {
      hideForId[id] = true;
    });
    return hideForId;
  })();

  /** @type {Map} 表示カラムの制御用Map */
  let hideForId = Object.fromEntries(ids.map((id) => [id, false]));
  $: $hiddenColumnIds = Object.entries(hideForId)
    .filter(([, hide]) => hide)
    .map(([id]) => id);
  $: filterResultsNum = $rows.length;

  let filteredColumnsForSearch = appContext.filteredColumnsForSearch ?? [];

  /** @type {boolean} 列フィルターの表示状態 */
  let columnsFilterIsOpen = false;

  /**
   * 荷受けからの経過日数を算出する。
   * @param {string} inputDay
   * @returns {number}
   */
  function calculateDaysFromReleasedAt(inputDay) {
    const now = new Date();
    const inTransitAt = new Date(inputDay);
    const startOfNow = startOfDay(now);
    const startOfInTransitAt = startOfDay(inTransitAt);
    const daysPassed = differenceInDays(startOfNow, startOfInTransitAt);
    return daysPassed;
  }

  /**
   * 表示カラムの非表示・表示を切り替え + Contextに状態を保持。
   * @param {string} id
   * @param {boolean} willStore
   */
  async function changeHiddenColumns(id, willStore) {
    hideForId[id] = !displayControlHideForId[id];

    if (willStore) {
      if (!displayControlHideForId[id]) {
        filteredColumnsForSearch.push(id);
      } else {
        filteredColumnsForSearch = filteredColumnsForSearch.filter(
          (columnId) => columnId !== id,
        );
      }
      appContext.filteredColumnsForSearch = filteredColumnsForSearch;
      appContext.store();
    }
  }

  /** @type {Map<string, string>} カラムの幅 */
  const fixedColumnWidth = new Map([
    ["statusIcon", ""],
    ["detailButton", ""],
    ["trackingNumber", "90px"],
    ["status", "250px"],
    ["ecCompanyName", "80px"],
    ["customerOrderId", "120px"],
    ["receiverPostcode", "100px"],
    ["receiverAddress1", "270px"],
    ["receiverAddress2", "270px"],
    ["correctedReceiverAddress", "270px"],
    ["receiverName", "100px"],
    ["quantity", "40px"],
    ["releasedAt", "130px"],
    ["relayLocationId", "200px"],
    ["desiredDate", "180px"],
    ["inTransitAt", "200px"],
    ["numberOfDeliveryAttempts", "80px"],
    ["cashOnDeliveryAmount", "60px"],
    ["driverDisplayName", "150px"],
    ["shippingPartnerInternalMessage", "200px"],
  ]);

  /**
   * カラムの幅を取得する
   * @param {string} id
   * @returns {string}
   */
  function getColumnWidthStyle(id) {
    return `width: ${fixedColumnWidth.get(id)}`;
  }

  onMount(async () => {
    if (filteredColumnsForSearch.length > 0) {
      // appContextに表示カラム設定がある場合は反映
      filteredColumnsForSearch.forEach((columnId) => {
        displayControlHideForId[columnId] = false;
        // 指定したカラムIDについて非表示・表示を切り替え (マウント時はContextに保存しない)
        changeHiddenColumns(columnId, false);
      });
    }
  });
</script>

{#if $pageCount > 1}
  <div class="paginationArea">
    <div class="pageSelect">
      ページ
      <select bind:value={$pageIndex}>
        {#each Array.from({ length: $pageCount }, (_, i) => i) as page}
          <option value={page} on:select={() => ($pageIndex = page + 1)}
            >{page + 1}</option
          >
        {/each}
      </select>
      /
      {$pageCount}
    </div>
    <Button
      variant="unelevated"
      on:click={() => $pageIndex--}
      disabled={!$hasPreviousPage}
      ><span class="material-icons">chevron_left</span></Button
    >
    <Button
      variant="unelevated"
      on:click={() => $pageIndex++}
      disabled={!$hasNextPage}
      ><span class="material-icons">chevron_right</span></Button
    >
  </div>
{/if}
<div class="mdc-data-table" class:hasPagination={$pageCount > 1}>
  <div
    class="mdc-data-table__table-container"
    class:openColumnsFilterHeight={columnsFilterIsOpen}
  >
    <table class="mdc-data-table__table" {...$tableAttrs}>
      <thead {...$tableHeadAttrs}>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr class="mdc-data-table__header-row" {...rowAttrs}>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <th
                    class="mdc-data-table__header-cell"
                    {...attrs}
                    style={cell.id === "statusIcon" ||
                    cell.id === "detailButton"
                      ? ""
                      : "cursor: pointer;"}
                    on:click={props.sort.toggle}
                  >
                    {#if cell.id === "statusIcon"}
                      <div class="columnsFilter">
                        <button
                          class="columnsFilterButton"
                          on:click={() => {
                            columnsFilterIsOpen = !columnsFilterIsOpen;
                          }}
                        >
                          <span class="material-icons md-18">filter_alt</span
                          >列フィルター<span
                            class="material-icons md-18"
                            class:isOpen={columnsFilterIsOpen}
                          >
                            expand_more
                          </span>
                        </button>
                        {#if columnsFilterIsOpen}
                          <div class="columnsFilterMenu">
                            {#each displayControlIds as id}
                              <div class="columnsFilterMenuItem">
                                <input
                                  id="hide-{id}"
                                  type="checkbox"
                                  bind:checked={displayControlHideForId[id]}
                                  on:change={() => {
                                    changeHiddenColumns(id, true);
                                  }}
                                />
                                <label for="hide-{id}"
                                  >{$_(`classes.columns.${id}`)}</label
                                >
                              </div>
                            {/each}
                          </div>
                        {/if}
                      </div>
                    {/if}
                    <div class="th-item" style={getColumnWidthStyle(cell.id)}>
                      <Render of={cell.render()} />
                      {#if props.sort.order === "asc"}
                        <span class="material-icons">arrow_upward</span>
                      {:else if props.sort.order === "desc"}
                        <span class="material-icons">arrow_downward</span>
                      {/if}
                    </div>
                    {#if props.filter?.render}
                      <div class="filter-area">
                        <Render of={props.filter.render} />
                      </div>
                    {/if}
                  </th>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
        {#if $rows.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note" colspan="20">
              該当するデータがありません
            </td>
          </tr>
        {:else}
          {#each $pageRows as row (row.id)}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
              <SearchResultRow {rowAttrs} let:inView>
                {#if inView}
                  {#each row.cells as cell (cell.id)}
                    <Subscribe attrs={cell.attrs()} let:attrs>
                      <td
                        class="mdc-data-table__cell"
                        class:numericalValue={cell.id === "inTransitAt" ||
                          cell.id === "numberOfDeliveryAttempts" ||
                          cell.id === "cashOnDeliveryAmount" ||
                          cell.id === "quantity"}
                        {...attrs}
                      >
                        {#if cell.id === "status"}
                          <SearchResultTableStatus
                            status={cell.render()}
                            hasShippingPartnerAdminRole={userContext.hasShippingPartnerAdminRole()}
                          />
                        {:else if cell.id === "quantity"}
                          {`${cell.render()}個`}
                        {:else if cell.id === "inTransitAt"}
                          {cell.render() !== "未登録"
                            ? `${formatStringDate(
                                cell.render(),
                                "yyyy/MM/dd(E) HH:mm",
                                {
                                  locale: localeJa,
                                },
                              )} (${calculateDaysFromReleasedAt(cell.render())}日経過)`
                            : cell.render()}
                        {:else if cell.id === "numberOfDeliveryAttempts"}
                          {`${cell.render()}回`}
                        {:else if cell.id === "cashOnDeliveryAmount"}
                          {cell.render() !== "0"
                            ? `${Number(cell.render()).toLocaleString()}円`
                            : ""}
                        {:else}
                          <Render of={cell.render()} />
                        {/if}
                      </td>
                    </Subscribe>
                  {/each}
                {:else}
                  <td class="mdc-data-table__cell"></td>
                {/if}
              </SearchResultRow>
            </Subscribe>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .paginationArea {
    display: flex;
    margin: 0 0 5px 10px;

    .pageSelect {
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-right: 15px;

      :global(.mdc-select__anchor) {
        padding: 0 5px;
        width: 50px;
        min-width: 40px;
        height: 25px;
        margin: 0 5px;
      }

      :global(.mdc-select__dropdown-icon) {
        margin: 0 -5px 0 0;
      }

      :global(.mdc-select__selected-text) {
        font-size: 13px;
      }

      :global(.mdc-menu) {
        min-width: 0;
      }

      :global(.mdc-deprecated-list-item) {
        height: 25px;
        font-size: 13px;
      }
    }

    :global(.mdc-button) {
      height: 25px;
      margin-right: 5px;
    }
  }
  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 220px);
    overflow-x: auto;
    overflow-y: visible;

    &.hasPagination {
      min-height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
    }

    .columnsFilter {
      position: absolute;
      top: 15px;
      left: 20px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;

      .columnsFilterButton {
        font-size: 11px;
        color: black;
        display: flex;
        padding: 4px 0 4px 2px;
        background-color: rgba(180, 208, 241, 0.796);
        border: none;
        border-radius: 5px;
        vertical-align: middle;
        cursor: pointer;

        .isOpen {
          transform: rotate(180deg);
          height: 17px;
        }
      }

      .columnsFilterButton:hover {
        background-color: rgb(143, 180, 225);
      }

      .columnsFilterMenu {
        background-color: white;
        display: column;
        align-items: center;
        padding: 7px 10px;
        font-size: 13px;
        width: 200px;
        border: 1px solid #d3d3d3;
        border-radius: 5px;

        .columnsFilterMenuItem {
          padding: 1px 0;
        }
      }

      :global(.mdc-menu-surface) {
        position: absolute;
        top: 0;
      }
    }

    .mdc-data-table__table-container {
      &.openColumnsFilterHeight {
        min-height: 520px;
      }
    }
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
    white-space: normal;
    word-wrap: break-word;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
  .numericalValue {
    text-align: right;
  }

  select {
    text-align: center;
    width: 50px;
    height: 25px;
    margin: 0 5px;
    font-size: 13px;
    border-radius: 5px;
    border: #ccc solid 1px;
    background-color: #fff;
    cursor: pointer;
  }

  .no_data_note {
    text-align: left;
    padding: 10px;
  }
</style>
