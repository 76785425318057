<script>
  import Button from "@smui/button";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import {
    differenceInCalendarDays,
    endOfMonth,
    format as formatDate,
    parseISO,
    startOfMonth,
  } from "date-fns";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    searchConditionType,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import loadingProgress from "~/libs/loadingProgress";
  import { messageDialogClose } from "~/libs/stores";
  import { getCurrentDateTimeOnJst } from "~/libs/utils";
  import EcSwitchableInfo from "~/pages/Main/EcSwitchableInfo.svelte";
  import DailyReportDownloadedDialog from "~/pages/Search/DailyReportDownloadedDialog.svelte";
  import SearchFilterSettingDialog from "~/pages/Search/SearchFilterSettingDialog.svelte";
  import SearchResult from "~/pages/Search/SearchResult.svelte";
  import { addDisplayControlProperties } from "~/pages/Search/SearchUtil";

  export let inputTrackingNumber = "";
  let directSearchFlg = false;
  $: if (inputTrackingNumber) {
    directSearchFlg = true;
  }

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  /** @type {string} 日付入力フォームの値の日付フォーマット */
  const INPUT_DATE_FORMAT = "yyyy-MM-dd";

  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;

  let searchResult;
  /**
   * 検索でヒットした出荷情報の配列(有効データのみ)
   * @type {Array<import("~/libs/backendApi").SearchedShipment>}
   */
  let results = [];
  let noDataCommentDisplay = "none";
  let noResultsCommentDisplay = "block";
  let notInputtedErrorMsgDisplay = "none";
  let trackingNumberErrorMsgDisplay = "none";
  let centerNotSelectedErrorMsgDisplay = "none";
  let ecNotSelectedErrorMsgDisplay = "none";
  let ecSearchNotInputtedErrorMsgDisplay = "none";
  let filterErrorMsgDisplay = "none";

  let inputCustomerId = "";
  let inputDayStart = inputTrackingNumber
    ? ""
    : formatDate(new Date(), INPUT_DATE_FORMAT);
  let inputDayEnd = inputDayStart;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  /** @type {import("~/libs/constants").searchConditionType} 検索済みのタイプ(CSVダウンロード制御用) */
  let searchedConditionType;
  /** @type {import("~/libs/backendApi").SearchShipmentRequest | import("~/libs/backendApi").SearchShipmentByLocationIdRequest} 検索済みのリクエスト(CSVダウンロード制御用) */
  let searchedCondition;
  /** @type {number} 検索でヒットした全件数(有効・無効データの合算) */
  let count;
  /** @type {number} 検索でヒットした無効データ件数 */
  let disabledCount;
  /** @type {boolean} */
  let maxLimitOver;
  /**
   * 選択された検索条件を格納する変数
   * - 0: 全データから検索
   * - 1: 配送センターから検索
   * - 2: EC事業者から検索
   * - 3: 月次集計
   * - 4: 日次レポート
   * @type {import("~/libs/constants").searchConditionType}
   */
  let selectConditions =
    userContext.hasShippingPartnerAdminRole() && inputTrackingNumber === ""
      ? searchConditionType.CENTER
      : searchConditionType.ALL;
  /**
   * EC事業者一覧を格納する配列
   * @type {Array<{ id: number, name: string }>}
   */
  let ecCompanies = [];
  /**
   * 検索条件にて選択されたEC事業者のIDを格納する変数
   * @type {number}
   */
  let inputEcCompanyId;
  /**
   *  出荷単位一覧を格納する配列
   * @type {import("~/libs/backendApi").SearchShippingUnitsResponse}
   */
  let shippingUnits = [];
  /**
   * ダイアログで選ばれた出荷単位のIDを格納する変数
   * @type {string}
   */
  let selectedShippingUnitId;
  /**
   * フィルター用のEC事業者IDを格納する変数
   * @type {number}
   */
  let ecId;
  /**
   * 検索条件にて選択された配送センターのIDを格納する変数
   * @type {number}
   */
  let inputCenterId = appContext.searchedCenterId ?? undefined;
  /**
   * 選択されたステータスを格納する変数（0: 今ある荷物, 1: これから来る荷物）
   * @type {0 | 1}
   */
  let selectedStatus = 0;
  /**
   * 配送センター一覧を格納する配列
   * @type {Array<import("~/libs/commonTypes").DepotLocation>}
   */
  let locationList = [];
  /**
   * 集計月を格納する変数
   * @type {string}
   */
  let aggregateMonth;
  /**
   * 集計月のリスト
   * @type {Array<{ param: string, month: string }>}
   */
  let aggregateMonthList = [];
  /**
   * 日次レポートをダウンロードした日時
   * @type {Date}
   */
  let dateOfDownloadDailyReport;
  /**
   * 日次レポートダウンロード完了のダイアログ
   * @type {DailyReportDownloadedDialog}
   */
  let dailyReportDownloadedDialog;
  /**
   * エラーメッセージを表示するかどうかを格納する変数
   * @type {boolean}
   */
  let displaysErrorMessage = false;
  /**
   * 注文番号の入力欄の無効化状態を格納する変数
   * @type {boolean}
   */
  let disablesInputCustomerOrderId = false;
  /**
   * 期間の入力欄の無効化状態を格納する変数。EC管理者による検索の際に更新される。
   * @type {boolean}
   */
  let disablesInputDayForEcAdmin = false;
  /**
   * 期間の入力欄の無効化状態を格納する変数。全データから検索する際に更新される。
   * @type {boolean}
   */
  let disablesInputDaySearchFromAll = false;
  /**
   * 期間の入力欄の無効化状態を格納する変数。EC事業者から検索する際に更新される。
   * @type {boolean}
   */
  let disablesInputDaySearchFromEc = false;

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  let centersMap;

  /** 切替え可能なEC事業者名 @type {string} */
  let switchableEcName;

  $: disablesInputCustomerOrderId = inputTrackingNumber.length > 0;
  $: disablesInputDayForEcAdmin =
    inputTrackingNumber.length > 0 || inputCustomerId.length > 0;
  $: disablesInputDaySearchFromAll = inputTrackingNumber.length > 0;
  $: disablesInputDaySearchFromEc = inputCustomerId.length > 0;
  $: displaysErrorMessage =
    notInputtedErrorMsgDisplay === "block" ||
    trackingNumberErrorMsgDisplay === "block" ||
    centerNotSelectedErrorMsgDisplay === "block" ||
    ecNotSelectedErrorMsgDisplay === "block" ||
    ecSearchNotInputtedErrorMsgDisplay === "block" ||
    filterErrorMsgDisplay === "block";

  // ページの初期化処理（非同期）
  (async () => {
    // 配送センター一覧の取得
    locationList = await depotLocations.get();
    if (
      userContext.hasShippingPartnerAdminRole() &&
      !userContext.hasTrumpAdminRole()
    ) {
      // トランプ以外の宅配パートナー管理者の場合、配送センターを自身の管轄のものに絞り込む
      for (let i = 0; i < locationList.length; i++) {
        for (let j = 0; j < locationList[i].centers.length; j++) {
          if (
            locationList[i].centers[j].companyId !==
            userContext.loginUser?.companyId
          ) {
            locationList[i].centers.splice(j, 1);
            j--;
          }
        }
        if (locationList[i].centers.length === 0) {
          locationList.splice(i, 1);
          i--;
        }
      }
    }

    // 配送センター情報のマップを取得
    centersMap = await depotLocations.getCentersMap();

    // 集計月のリストを作成
    const today = getCurrentDateTimeOnJst();
    today.setMonth(today.getMonth() - 1);
    for (let i = 0; i < 6; i++) {
      const param = formatDate(today, "yyyy-MM");
      const month = formatDate(today, "yyyy年MM月");
      aggregateMonthList.push({ param: param, month: month });
      today.setMonth(today.getMonth() - 1);
    }
  })();

  onMount(() => {
    if (inputTrackingNumber) {
      search();
    }
    if (!userContext.hasEcAdminRole()) {
      getEcCompanies();
    }
  });

  onDestroy(() => {
    messageDialogCloseUnsubscriber?.();
  });

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  /** ダイアログコンポーネントを削除する */
  function dialogCloseHandler() {
    dialogComponent = null;
  }

  function displayMessageDialog(title, message) {
    dialogTitle = title;
    dialogMessage = message;
    dialogComponent = MessageDialog;
  }

  function createMonthlyAggregationFileName() {
    let filename = "monthlyAggregation_";
    filename += aggregateMonth.substring(0, 4) + "-";
    filename += aggregateMonth.substring(5, 7) + ".csv";
    return filename;
  }

  function createDailyReportFileName() {
    const ecConpanyIndex = ecCompanies.findIndex(
      (e) => e.id === inputEcCompanyId,
    );

    let filename = "dailyReport_";
    filename += ecCompanies[ecConpanyIndex].name + "_";
    filename +=
      formatDate(dateOfDownloadDailyReport, INPUT_DATE_FORMAT) + ".xlsx";
    return filename;
  }

  function downloadMonthlyAggregationComplete() {
    displayMessageDialog(
      "ダウンロード完了",
      "CSVファイルのダウンロードが完了しました。",
    );
  }

  function downloadDailyReportComplete() {
    displayMessageDialog(
      "ダウンロード完了",
      "日次レポートのダウンロードが完了しました。",
    );
  }

  /**
   * 検索APIを実行する。
   * @returns {Promise<import("~/libs/backendApi").SearchShipmentResponse>}
   */
  const execSearchApi = async () => {
    /** @type {import("~/libs/backendApi").SearchShipmentRequest}*/
    const searchShipmentRequest = {};
    if (selectConditions === searchConditionType.ALL) {
      // 全データ検索の場合のみ、送り状番号を設定
      if (inputTrackingNumber) {
        searchShipmentRequest.trackingNumber = inputTrackingNumber.replace(
          /-/g,
          "",
        );
      }

      if (userContext.hasEcAdminRole() && selectedShippingUnitId) {
        // EC管理者、かつ出荷単位が選択されている場合、出荷単位IDを設定
        searchShipmentRequest.shippingUnitId = selectedShippingUnitId;
      }
    }

    if (
      (userContext.hasEcAdminRole() && !disablesInputCustomerOrderId) ||
      selectConditions === searchConditionType.EC
    ) {
      // EC事業管理者による検索かつ入力欄が有効な場合、またはEC事業者から検索の場合、注文番号を設定
      if (inputCustomerId) {
        searchShipmentRequest.customerOrderId = inputCustomerId;
      }
    }

    if (selectConditions === searchConditionType.EC) {
      // EC事業者からの検索の場合のみ、EC事業者IDと出荷番号を設定
      searchShipmentRequest.ecId = inputEcCompanyId;
      if (selectedShippingUnitId) {
        searchShipmentRequest.shippingUnitId = selectedShippingUnitId;
      }
    }

    if (inputDayStart || inputDayEnd) {
      // 検索期間は全データ検索、EC事業者からの検索どちらの場合も設定
      if (
        (userContext.hasEcAdminRole() && !disablesInputDayForEcAdmin) ||
        (!userContext.hasEcAdminRole() &&
          selectConditions === searchConditionType.ALL &&
          !disablesInputDaySearchFromAll) ||
        (selectConditions === searchConditionType.EC &&
          !disablesInputDaySearchFromEc)
      ) {
        // なお、入力欄が有効な場合のみ設定
        searchShipmentRequest.searchDate = {
          start: inputDayStart,
          end: inputDayEnd,
        };
      }
    }

    const response = await backendApi.searchShipments(searchShipmentRequest);
    searchedCondition = searchShipmentRequest;
    return response;
  };

  /**
   * 配送センター別検索APIを実行する。
   * @returns {Promise<import("~/libs/backendApi").SearchShipmentResponse>}
   */
  const execSearchByLocationIdApi = async () => {
    /** @type {import("~/libs/backendApi").SearchShipmentByLocationIdRequest}*/
    const searchShipmentRequest = {};

    searchShipmentRequest.relayLocationId = inputCenterId;
    searchShipmentRequest.statusType = selectedStatus;

    const response = await backendApi.searchShipmentsByLocationId(
      searchShipmentRequest,
    );
    searchedCondition = searchShipmentRequest;
    response.disabledCount = 0;
    response.maxLimitOver = false;
    return response;
  };

  const search = loadingProgress.wrapAsync(async () => {
    if (directSearchFlg) {
      // クエリパラメータによる直接検索の場合、拠点一覧の取得が完了していないため1秒待機
      directSearchFlg = false;
      await sleep(1000);
    }
    let searchErrorExists = false;
    noDisplayErrorMessage();

    // 検索条件の未入力チェック
    if (selectConditions === searchConditionType.ALL) {
      if (userContext.hasEcAdminRole()) {
        // EC事業管理者による全データ検索の場合
        if (
          inputTrackingNumber.length +
            inputCustomerId.length +
            inputDayStart.length +
            inputDayEnd.length ===
          0
        ) {
          // 送り状番号、注文番号、期間が入力されていない場合はエラーメッセージを表示
          notInputtedErrorMsgDisplay = "block";
          searchErrorExists = true;
        } else if (
          inputTrackingNumber.length < 12 &&
          inputTrackingNumber.length != 0
        ) {
          // 送り状番号が12文字未満の場合はエラーメッセージを表示
          trackingNumberErrorMsgDisplay = "block";
          searchErrorExists = true;
        }
      } else {
        // コントラクト・宅配パートナー管理者による全データ検索の場合
        if (
          inputTrackingNumber.length +
            inputDayStart.length +
            inputDayEnd.length ===
          0
        ) {
          // 送り状番号、期間が入力されていない場合はエラーメッセージを表示
          notInputtedErrorMsgDisplay = "block";
          searchErrorExists = true;
        } else if (
          inputTrackingNumber.length < 12 &&
          inputTrackingNumber.length != 0
        ) {
          // 送り状番号が12文字未満の場合はエラーメッセージを表示
          trackingNumberErrorMsgDisplay = "block";
          searchErrorExists = true;
        }
      }
    } else if (selectConditions === searchConditionType.CENTER) {
      // 配送センターからの検索の場合
      if (
        !Number.isInteger(inputCenterId) ||
        !Number.isInteger(selectedStatus)
      ) {
        // 配送センターが選択されていない場合はエラーメッセージを表示
        centerNotSelectedErrorMsgDisplay = "block";
        searchErrorExists = true;
      }
    } else if (selectConditions === searchConditionType.EC) {
      // EC事業者からの検索の場合
      if (!Number.isInteger(inputEcCompanyId)) {
        // EC事業者が選択されていない場合はエラーメッセージを表示
        ecNotSelectedErrorMsgDisplay = "block";
        searchErrorExists = true;
      } else if (
        // EC事業者のみ入っていて、注文番号も期間も入ってない場合はエラーメッセージを表示
        inputCustomerId.length === 0 &&
        inputDayStart.length === 0 &&
        inputDayEnd.length === 0
      ) {
        ecSearchNotInputtedErrorMsgDisplay = "block";
        searchErrorExists = true;
      }
    } else if (selectConditions === searchConditionType.REPORT) {
      // 日次レポートの場合
      if (!Number.isInteger(inputEcCompanyId)) {
        // EC事業者が選択されていない場合はエラーメッセージを表示
        ecNotSelectedErrorMsgDisplay = "block";
        searchErrorExists = true;
      }
    }

    if (!searchErrorExists) {
      if (
        selectConditions !== searchConditionType.SUMMARY &&
        selectConditions !== searchConditionType.REPORT
      ) {
        // 月次集計、日次レポート以外の場合
        searchResult = null;
        noResultsCommentDisplay = "block";
        noDataCommentDisplay = "none";

        try {
          if (
            selectConditions === searchConditionType.ALL ||
            selectConditions === searchConditionType.EC
          ) {
            ({
              shipments: results,
              count,
              disabledCount,
              maxLimitOver,
            } = await execSearchApi());
          } else if (selectConditions === searchConditionType.CENTER) {
            appContext.searchedCenterId = inputCenterId;
            appContext.store();
            ({
              shipments: results,
              count,
              disabledCount,
              maxLimitOver,
            } = await execSearchByLocationIdApi());
          }
          searchedConditionType = selectConditions;
          await addDisplayControlProperties(results);
          searchResult = SearchResult;
        } catch (error) {
          /** @type {import("~/libs/backendApi").ErrorResponse} */
          const errorResponse = error["errorResponse"];

          if (
            errorResponse?.title == "invalidParam" &&
            errorResponse?.details?.violations
          ) {
            /** @type {Array<{level: string, path: string, message: string}>} */
            const violations = errorResponse.details.violations;
            const outputViolations = violations
              .map(
                (v) => "・" + $_("classes.user." + v.path) + "：" + v.message,
              )
              .join("<br />");
            showErrorMessage(
              new HandledError(
                $_("errors.searchConditionError.title"),
                $_("errors.searchConditionError.message", {
                  values: { violations: outputViolations },
                }),
              ),
            );
          } else {
            noDataCommentDisplay = "block";
            console.error(error);
            showErrorMessage(error);
          }
        } finally {
          noResultsCommentDisplay = "none";
        }
      } else if (selectConditions === searchConditionType.SUMMARY) {
        // 月次集計の場合
        try {
          let blob = await backendApi.downloadMonthlyAggregationCsv({
            yearMonth: aggregateMonth,
          });
          let csvname = createMonthlyAggregationFileName();
          let url = (window.URL || window.webkitURL).createObjectURL(blob);
          let download = document.createElement("a");
          download.href = url;
          download.download = await csvname;
          download.click();
          (window.URL || window.webkitURL).revokeObjectURL(url);

          downloadMonthlyAggregationComplete();
        } catch (error) {
          if (error instanceof HTTPError && error.response?.status == 400) {
            displayMessageDialog(
              $_("errors.errorContaining.title"),
              $_("errors.errorContaining.message"),
            );
          } else {
            showErrorMessage(error);
          }
        }
      } else if (selectConditions === searchConditionType.REPORT) {
        // 日次レポートの場合
        try {
          // ダウンロード日時を保持
          dateOfDownloadDailyReport = new Date();

          // 日次レポートCSVをダウンロード
          let blob = await backendApi.downloadDailyReportCsv({
            companyId: inputEcCompanyId,
          });
          let csvname = createDailyReportFileName();
          let url = (window.URL || window.webkitURL).createObjectURL(blob);
          let download = document.createElement("a");
          download.href = url;
          download.download = await csvname;
          download.click();
          (window.URL || window.webkitURL).revokeObjectURL(url);

          if (userContext.hasTrumpAdminRole()) {
            // トランプの場合は報告済み登録のダイアログを表示
            dailyReportDownloadedDialog.dialogOpen();
          } else {
            // トランプ以外の場合はダウンロード完了のダイアログを表示
            downloadDailyReportComplete();
          }
        } catch (error) {
          if (error instanceof HTTPError && error.response?.status == 400) {
            displayMessageDialog(
              $_("errors.errorContaining.title"),
              $_("errors.errorContaining.message"),
            );
          } else {
            showErrorMessage(error);
          }
        }
      }
    }
  });

  /**
   * エラーメッセージを非表示にする。
   */
  function noDisplayErrorMessage() {
    notInputtedErrorMsgDisplay = "none";
    trackingNumberErrorMsgDisplay = "none";
    centerNotSelectedErrorMsgDisplay = "none";
    ecNotSelectedErrorMsgDisplay = "none";
    ecSearchNotInputtedErrorMsgDisplay = "none";
    filterErrorMsgDisplay = "none";
  }

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      displayMessageDialog(error.title, error.message);
    } else {
      if (error instanceof HTTPError && error.response?.status == 401) {
        displayMessageDialog(
          $_("errors.unauthorized.title"),
          $_("errors.unauthorized.message"),
        );
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        displayMessageDialog(
          $_("errors.forbidden.title"),
          $_("errors.forbidden.message"),
        );
      } else {
        console.error(error);
        displayMessageDialog(
          $_("errors.defaultMessage.title"),
          $_("errors.defaultMessage.message"),
        );
      }
    }
  }

  const clearInput = () => {
    inputTrackingNumber = "";
    inputCustomerId = "";
    inputDayStart = "";
    inputDayEnd = "";
  };

  /**
   * 検索条件の日付を調整する。
   * @param {string} start 開始日
   * @param {string} end 終了日
   * @param {boolean} isStartChanged 開始日が変更されたかどうか
   */
  async function adjustSearchDate(start, end, isStartChanged) {
    if (start === "" || end === "") {
      return;
    }

    const today = new Date();
    let startDate = parseISO(start);
    let endDate = parseISO(end);

    // 翌日以降を指定した場合は本日に変更
    if (differenceInCalendarDays(startDate, today) > 0) {
      startDate = today;
    }
    if (differenceInCalendarDays(endDate, today) > 0) {
      endDate = today;
    }

    // 開始日と終了日の前後関係の調整
    if (differenceInCalendarDays(startDate, endDate) > 0) {
      if (isStartChanged) {
        endDate = startDate;
      } else {
        startDate = endDate;
      }
    }

    // 開始日と終了日の間隔が1ヶ月（31日）を超える場合は月初/月末に変更
    if (differenceInCalendarDays(startDate, endDate) < -30) {
      if (isStartChanged) {
        endDate = endOfMonth(startDate);
      } else {
        startDate = startOfMonth(endDate);
      }
    }

    inputDayStart = formatDate(startDate, INPUT_DATE_FORMAT);
    inputDayEnd = formatDate(endDate, INPUT_DATE_FORMAT);
  }

  /**
   * 検索結果に表示する出荷情報のフィルタリングを行う。
   */
  async function filterData() {
    // 検索エラーメッセージを非表示にする
    noDisplayErrorMessage();

    if (userContext.hasEcAdminRole()) {
      // ログインユーザーがEC事業者の場合はログインユーザのIDを使用する
      ecId = Number(userContext.loginUser?.currentCompanyId);
    } else {
      // ログインユーザーがEC事業者でない場合は選択されたEC事業者のIDを使用する
      ecId = Number(inputEcCompanyId);
    }
    if (ecId === 0 || inputDayStart === "" || inputDayEnd === "") {
      // EC事業者名と期間が入力されていない場合はエラーメッセージを表示
      filterErrorMsgDisplay = "block";
      return;
    } else {
      filterErrorMsgDisplay = "none";
    }

    let body = {
      ecId: ecId,
      searchDate: {
        start: inputDayStart,
        end: inputDayEnd,
      },
    };

    try {
      shippingUnits = await backendApi.searchShippingUnits(body);
      dialogComponent = SearchFilterSettingDialog;
    } catch (error) {
      showErrorMessage(error);
    }
  }

  /**
   * ECサイト一覧を取得する
   */
  async function getEcCompanies() {
    ecCompanies = [];
    try {
      ecCompanies = await backendApi.getEcCompanies();
      ecCompanies.sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      showErrorMessage(error);
    }
  }
</script>

<svelte:component
  this={dialogComponent}
  {dialogTitle}
  {dialogMessage}
  {shippingUnits}
  {ecId}
  {dialogCloseHandler}
  bind:selectedShippingUnitId
/>
<DailyReportDownloadedDialog
  bind:this={dailyReportDownloadedDialog}
  bind:companyId={inputEcCompanyId}
  {dateOfDownloadDailyReport}
/>
<div class="wrapper">
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">配送情報の検索</h1>
  </div>
  {#if userContext.loginUser.switchableCompanies?.length > 1}
    <EcSwitchableInfo bind:switchableEcName>
      <p slot="description">
        {#if userContext.hasAnkAdminRole()}
          配送情報を検索する場合は {switchableEcName} ユーザーに切り替えてください。
        {/if}
      </p>
    </EcSwitchableInfo>
  {/if}
  {#if !userContext.hasAnkAdminRole()}
    <div class="businessArea">
      <div class="captionTabs">
        <input
          type="radio"
          name="selectConditions"
          id="allData"
          bind:group={selectConditions}
          on:change={() => {
            noDisplayErrorMessage();
          }}
          value={searchConditionType.ALL}
          checked
        />
        <label for="allData" class="selectConditions"> 全データから検索 </label>
        {#if !userContext.hasEcAdminRole()}
          <input
            type="radio"
            name="selectConditions"
            id="centerSelection"
            bind:group={selectConditions}
            on:change={() => {
              noDisplayErrorMessage();
            }}
            value={searchConditionType.CENTER}
          />
          <label for="centerSelection" class="selectConditions">
            配送センターから検索
          </label>
          <input
            type="radio"
            name="selectConditions"
            id="ecSelection"
            bind:group={selectConditions}
            on:change={() => {
              noDisplayErrorMessage();
            }}
            value={searchConditionType.EC}
          />
          <label for="ecSelection" class="selectConditions">
            EC事業者から検索
          </label>
        {/if}
        {#if userContext.hasShippingPartnerAdminRole()}
          <input
            type="radio"
            name="selectConditions"
            id="dailyReport"
            bind:group={selectConditions}
            on:change={() => {
              noDisplayErrorMessage();
              searchResult = null;
              noResultsCommentDisplay = "block";
              noDataCommentDisplay = "none";
            }}
            value={searchConditionType.REPORT}
          />
          <label for="dailyReport" class="selectConditions">日次レポート</label>
        {/if}
        <input
          type="radio"
          name="selectConditions"
          id="monthSelection"
          bind:group={selectConditions}
          on:change={() => {
            noDisplayErrorMessage();
            searchResult = null;
            noResultsCommentDisplay = "block";
            noDataCommentDisplay = "none";
          }}
          value={searchConditionType.SUMMARY}
        />
        <label for="monthSelection" class="selectConditions">月次集計</label>
      </div>
      <div class="searchConditions">
        <div class="featureDescription">
          <p>{$_(`pages.Search.featureDescription.${selectConditions}`)}</p>
          {#if selectConditions === searchConditionType.REPORT && !userContext.hasTrumpAdminRole()}
            <p class="deliveryPartnerNote">
              ※トランプの管理者が確認済みとしてマークした荷物は、翌日以降レポートに含まれなくなります
            </p>
          {/if}
          {#if displaysErrorMessage}
            <div class="errorArea">
              <span class="material-icons"> report_problem </span>
              <p
                class="errorMessage"
                style="display: {notInputtedErrorMsgDisplay}"
              >
                検索条件を入力してください。
              </p>
              <p
                class="errorMessage"
                style="display: {trackingNumberErrorMsgDisplay}"
              >
                送り状番号を正しく入力してください。
              </p>
              <p
                class="errorMessage"
                style="display: {centerNotSelectedErrorMsgDisplay}"
              >
                配送センターを選択してください。
              </p>
              <p
                class="errorMessage"
                style="display: {ecNotSelectedErrorMsgDisplay}"
              >
                EC事業者名を選択してください。
              </p>
              <p
                class="errorMessage"
                style="display: {ecSearchNotInputtedErrorMsgDisplay}"
              >
                注文番号か期間を入力してください。
              </p>
              <p class="errorMessage" style="display: {filterErrorMsgDisplay}">
                データの絞り込みにはEC事業者名の選択と期間の指定が必要です。
              </p>
            </div>
          {/if}
        </div>
        {#if selectConditions === searchConditionType.ALL}
          {#if userContext.hasEcAdminRole()}
            <div class="row">
              <div class="th">送り状番号</div>
              <div class="td">
                <input
                  type="text"
                  placeholder=""
                  bind:value={inputTrackingNumber}
                  on:keydown={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </div>
              <div class="th">注文番号</div>
              <div class="td">
                <input
                  type="text"
                  placeholder=""
                  disabled={disablesInputCustomerOrderId}
                  bind:value={inputCustomerId}
                  on:keydown={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </div>
            </div>
            <div class="row">
              <div class="th">期間</div>
              <div class="td">
                <input
                  type="date"
                  disabled={disablesInputDayForEcAdmin}
                  bind:value={inputDayStart}
                  on:change={(event) => {
                    adjustSearchDate(
                      /** @type {HTMLInputElement} */ (event.target).value,
                      inputDayEnd,
                      true,
                    );
                  }}
                />
              </div>
              <div class="td">から</div>
              <div class="td">
                <input
                  type="date"
                  disabled={disablesInputDayForEcAdmin}
                  bind:value={inputDayEnd}
                  on:change={(event) => {
                    adjustSearchDate(
                      inputDayStart,
                      /** @type {HTMLInputElement} */ (event.target).value,
                      false,
                    );
                  }}
                />
              </div>
              <div class="td">までの登録データを参照する</div>
              <button class="filterButton" on:click={filterData}>
                <span class="material-icons">filter_list</span>
              </button>
            </div>
          {:else}
            <div class="row">
              <div class="th">送り状番号</div>
              <div class="td">
                <input
                  type="text"
                  placeholder=""
                  bind:value={inputTrackingNumber}
                  on:keydown={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </div>
            </div>
            <div class="row">
              <div class="th">期間</div>
              <div class="td">
                <input
                  type="date"
                  disabled={disablesInputDaySearchFromAll}
                  bind:value={inputDayStart}
                  on:change={(event) => {
                    adjustSearchDate(
                      /** @type {HTMLInputElement} */ (event.target).value,
                      inputDayEnd,
                      true,
                    );
                  }}
                />
              </div>
              <div class="td">から</div>
              <div class="td">
                <input
                  type="date"
                  disabled={disablesInputDaySearchFromAll}
                  bind:value={inputDayEnd}
                  on:change={(event) => {
                    adjustSearchDate(
                      inputDayStart,
                      /** @type {HTMLInputElement} */ (event.target).value,
                      false,
                    );
                  }}
                />
              </div>
              <div class="td">までの登録データを参照する</div>
            </div>
          {/if}
        {:else if selectConditions === searchConditionType.CENTER}
          <div class="row">
            <div class="th">配送センター</div>
            <div class="td">
              <select
                name="centerName"
                id="centerName"
                bind:value={inputCenterId}
              >
                <option value="" selected disabled>選択してください</option>
                {#each locationList as { prefecture, centers }}
                  <optgroup label={prefecture}>
                    {#each centers as { id, name }}
                      <option value={id}>{name}</option>
                    {/each}
                  </optgroup>
                {/each}
              </select>
            </div>
          </div>
          <div class="row">
            <div class="th">ステータス</div>
            <div class="td">
              {#each [0, 1] as status}
                <FormField>
                  <Radio bind:group={selectedStatus} value={status} />
                  <span class="statusLabel" slot="label"
                    >{$_(`pages.Search.statusLabel.${status}`)}</span
                  >
                </FormField>
              {/each}
            </div>
          </div>
        {:else if selectConditions === searchConditionType.EC}
          <div class="row">
            <div class="th">EC事業者名</div>
            <div class="td">
              <select
                name="ecCompanyName"
                id="ecCompanyName"
                bind:value={inputEcCompanyId}
              >
                <option value="" selected disabled>選択してください</option>
                {#each ecCompanies as { id, name }}
                  <option value={id}>{name}</option>
                {/each}
              </select>
            </div>
            <div class="th">注文番号</div>
            <div class="td">
              <input
                type="text"
                placeholder=""
                bind:value={inputCustomerId}
                on:keydown={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />
            </div>
          </div>
          <div class="row">
            <div class="th">期間</div>
            <div class="td">
              <input
                type="date"
                disabled={disablesInputDaySearchFromEc}
                bind:value={inputDayStart}
                on:change={(event) => {
                  adjustSearchDate(
                    /** @type {HTMLInputElement} */ (event.target).value,
                    inputDayEnd,
                    true,
                  );
                }}
              />
            </div>
            <div class="td">から</div>
            <div class="td">
              <input
                type="date"
                disabled={disablesInputDaySearchFromEc}
                bind:value={inputDayEnd}
                on:change={(event) => {
                  adjustSearchDate(
                    inputDayStart,
                    /** @type {HTMLInputElement} */ (event.target).value,
                    false,
                  );
                }}
              />
            </div>
            <div class="td">までの登録データを参照する</div>
            <button class="filterButton" on:click={filterData}>
              <span class="material-icons">filter_list</span>
            </button>
          </div>
        {:else if selectConditions === searchConditionType.SUMMARY}
          <div class="row">
            <div class="th">集計月</div>
            <div class="td">
              <select
                name="aggregateMonth"
                id="aggregateMonth"
                bind:value={aggregateMonth}
              >
                {#each aggregateMonthList as { param, month }}
                  <option value={param}>{month}</option>
                {/each}
              </select>
            </div>
          </div>
        {:else if selectConditions === searchConditionType.REPORT}
          <div class="row">
            <div class="th">EC事業者名</div>
            <div class="td">
              <select
                name="ecCompanyName"
                id="ecCompanyName"
                bind:value={inputEcCompanyId}
              >
                <option value="" selected disabled>選択してください</option>
                {#each ecCompanies as { id, name }}
                  <option value={id}>{name}</option>
                {/each}
              </select>
            </div>
          </div>
        {/if}
      </div>
      <div class="buttonArea">
        {#if selectConditions === searchConditionType.ALL || selectConditions === searchConditionType.EC}
          <Button
            style="color:rgba(255, 255, 255, 1); background-color:rgba(180, 208, 241, 1);"
            touch
            on:click={clearInput}
            variant="unelevated"
            >検索条件をクリア
          </Button>
        {/if}
        {#if selectConditions !== searchConditionType.SUMMARY && selectConditions !== searchConditionType.REPORT}
          <Button touch variant="unelevated" on:click={search}
            >検&nbsp;&nbsp;索
          </Button>
        {:else}
          <Button touch variant="unelevated" on:click={search}
            >ダウンロード
          </Button>
        {/if}
      </div>

      {#if selectConditions !== searchConditionType.SUMMARY && selectConditions !== searchConditionType.REPORT}
        <div class="captionArea">検索結果一覧</div>
        <div class="resultArea">
          <div id="noResultsComment" style="display: {noResultsCommentDisplay}">
            検索後に結果が表示されます。
          </div>
          <div id="datatable">
            <div id="noDataComment" style="display:{noDataCommentDisplay}">
              該当するデータがありません。
            </div>
            <svelte:component
              this={searchResult}
              {results}
              {searchedConditionType}
              {searchedCondition}
              {count}
              {disabledCount}
              {maxLimitOver}
              {centersMap}
            />
          </div>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }
  .titleArea {
    height: 40px;
    display: flex;
  }
  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }
  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }
  .businessArea {
    margin: 16px 0px 0px 0px;
    display: columns;
  }
  .captionArea {
    background-color: #064491cb;
    width: 200px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }
  .captionTabs {
    display: flex;
    justify-content: start;
  }
  .selectConditions {
    background-color: #0645917e;
    width: fit-content;
    min-width: fit-content;
    padding-right: 18px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }
  .selectConditions:hover {
    filter: brightness(1.2);
  }
  input[name="selectConditions"] {
    display: none;
  }
  .captionTabs input:checked + .selectConditions {
    background-color: #064491cb;
  }

  .searchConditions {
    width: 758px;
    position: relative;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0 5px 5px 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .featureDescription {
      margin: 5px 0 8px 0;
      font-size: 14px;

      .deliveryPartnerNote {
        line-height: 1.5;
      }

      .errorArea {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 4px;
        padding: 8px 14px;
        margin: 8px 14px 0 0;
        font-weight: bolder;
        color: #c80000;
        background-color: #ffe7e7;
        border-radius: 4px;
        line-height: 20px;

        .material-icons {
          color: #c80000;
        }
      }
    }

    .row {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .th {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 144px;
      height: 50px;
      background-color: #b4d0f1cb;
      color: #242424;
      font-size: small;
      font-weight: 900;
    }

    .statusLabel {
      margin: 0 20px 0 -5px;
    }
  }

  input[type="text"] {
    width: 220px;
    height: 42px;
    font-size: 15px;
  }
  input[type="date"] {
    width: 140px;
    height: 42px;
    font-size: 14px;
    position: relative;

    &::-webkit-datetime-edit {
      margin: 0 4px;
    }
    &::-webkit-datetime-edit-text {
      margin: 0 1px;
    }
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-image: none;
    }
  }
  select {
    width: 228px;
    height: 50px;
    font-size: 15px;
  }
  .filterButton {
    width: 40px;
    height: 40px;
    border: 1px solid #666;
    border-radius: 2px;
    background-color: #eee;
    color: #242424;
    font-size: 24px;
    margin-left: 16px;
    span {
      position: relative;
      top: 2px;
    }
  }
  .filterButton:hover,
  .filterButton:focus {
    background-color: #eeeeee90;
  }
  .buttonArea {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 16px;
    width: 800px;

    :global(.mdc-button) {
      margin: 0 10px;
      height: 40px;
      width: 156px;
    }
  }
  .resultArea {
    width: calc(100% - 12px);
    min-width: 820px;
    height: fit-content;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }
  #noResultsComment {
    margin: 10px;
    font-size: smaller;
  }
  #noDataComment {
    margin: 10px;
    font-size: smaller;
  }
</style>
