<script>
  import { getContext, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";

  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import { activeContent, targetTrackingNumber } from "~/libs/stores";
  import Activity from "~/pages/Activity.svelte";
  import Ecmall from "~/pages/Ecmall.svelte";
  import Home from "~/pages/Home.svelte";
  import Knowledge from "~/pages/Knowledge.svelte";
  import Header from "~/pages/Main/Header.svelte";
  import Sidebar from "~/pages/Main/Sidebar.svelte";
  import Management from "~/pages/Management.svelte";
  import Search from "~/pages/Search.svelte";
  import UploadMain from "~/pages/Upload.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    activeContent.set(null);
    push("/");
  }

  /** @type {import("svelte/store").Unsubscriber} */
  let activeContentUnsubscriber;

  let content;
  let inputTrackingNumber;

  /**
   * @type {boolean}
   * 画面幅が1171px以下の際に、サイドメニューを開いているかどうか
   */
  let menuOpened = false;

  // ページ初期化処理（async禁止）
  (() => {
    // 配送センター一覧を事前キャッシュ
    depotLocations.get();
  })();

  onDestroy(() => {
    activeContentUnsubscriber?.();
  });

  activeContentUnsubscriber = activeContent.subscribe((value) => {
    inputTrackingNumber = "";
    switch (value) {
      case "Home":
        content = Home;
        break;
      case "Upload":
        content = UploadMain;
        break;
      case "Search":
        if ($targetTrackingNumber) {
          inputTrackingNumber = $targetTrackingNumber;
          targetTrackingNumber.set("");
        }
        content = Search;
        break;
      case "Activity":
        content = Activity;
        break;
      case "Knowledge":
        content = Knowledge;
        break;
      case "Management":
        content = Management;
        break;
      case "Ecmall":
        content = Ecmall;
        break;
    }
  });

  function toggleMenu(event) {
    menuOpened = event.detail.menuOpened;
  }
</script>

<body>
  <div class="app">
    <Header on:menu={toggleMenu} />
    <div class="main {menuOpened ? 'open' : ''}">
      <Sidebar />
      <div class="content pc">
        <svelte:component this={content} {inputTrackingNumber} />
      </div>
    </div>
  </div>
</body>

<style>
  body {
    overflow-y: auto;
    scrollbar-gutter: stable;
    background-color: rgb(241, 239, 239);
  }
  .app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .main {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
  }
  .content {
    background-color: rgb(241, 239, 239);
    width: 100%;
    flex-direction: column;
    overflow: auto;
    padding: 0px 0px 0px 298px;
  }

  @media screen and (max-width: 1171px) {
    .main {
      left: -298px;
      transition: 0.5s;
      width: calc(100% + 298px);
    }
    .main.open {
      left: 0;
    }
  }
</style>
