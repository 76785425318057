<script>
  import Chip, { Set, Text } from "@smui/chips";
  import LinearProgress from "@smui/linear-progress";
  import Switch from "@smui/switch";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import { getContext, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import backendApi, { ErrorResponseException } from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { messageDialogClose } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import { formatUtcToJst } from "~/libs/utils";
  import DataTable from "~/pages/Upload/PreUploadDataTable.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** アップロード可能な出荷データファイルの拡張子 */
  const validFileExtensions = ["csv", "xlsx"];

  /** 出荷データ登録メニューを切替可能な場合にtrue */
  export let switchable;
  $: switchable = !registerFinished;

  /** @type {FileList} */
  let files;
  /** @type {File} */
  let readedFile;
  let fileName = "ファイルが選択されていません。";
  let fileType;
  let results = [];
  let formatType = "2";
  let withHeader = userContext.ecSettings.csvUploadHasHeader;
  let datatableDisplay = "none";
  let noResultsCommentDisplay = "block";
  let progressBarDisplay = "none";
  let noDataCommentDisplay = "none";
  let uploadButtonDisabled = true;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  let dataTable;
  let choices = ["全て表示", "正常のみ表示", "エラーのみ表示"];
  let selected = "全て表示";
  let previousSelected = "全て表示";
  let downloadButtonDisabled = false;
  /**
   * ダウンロードする荷物情報の一覧
   * @type {Array}
   */
  let downloadList = [];
  /**
   * 出荷番号
   * @type {string}
   */
  let shippingUnitId;
  /**
   * 発行作業の進捗状況(true:実行済/false:未実行)
   * @type {boolean}
   */
  let registerFinished = false;
  /**
   * 削除完了メッセージ表示状況(true:表示/false:非表示)
   * @type {boolean}
   */
  let deleteCompleted = false;
  /**
   * EC事業者の代引可否(true:代引可/false:代引不可)
   * @type {boolean}
   */
  let supportCashOnDelivery = userContext.ecSettings.supportCashOnDelivery;
  /**
   * 発行日時をISO8601基本形式UTCで表し、BEで扱う形式にフォーマットしたもの
   * 例 : 20240202T050000Z
   * @type {string}
   */
  let releasedAtUTC;

  /**
   * ダウンロードするファイルのURL
   * @type {string}
   */
  let downloadUrl;

  /** @type {{全て表示: number, 正常のみ表示: number, エラーのみ表示: number}} */
  let numberOfRecords;
  $: if (results && results.length > 0) {
    countingNumberOfRecords();
  } else {
    numberOfRecords = null;
  }

  function countingNumberOfRecords() {
    const sum = [0, 0];
    for (const result of results) {
      sum[result.violations ? 1 : 0]++;
    }
    numberOfRecords = {
      全て表示: sum[0] + sum[1],
      正常のみ表示: sum[0],
      エラーのみ表示: sum[1],
    };
    numberOfRecords.エラーのみ表示 > 0
      ? (downloadButtonDisabled = true)
      : (downloadButtonDisabled = false);
  }

  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;
  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogComponent = null;
    messageDialogClose.set(false);
  });

  onDestroy(() => {
    messageDialogCloseUnsubscriber?.();
  });

  $: if (files) {
    if (files.length > 0 && files[0] != readedFile) {
      readedFile = files[0];
      fileName = readedFile.name;
      fileType = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      if (validFileExtensions.includes(fileType) && readedFile.size > 0) {
        uploadButtonDisabled = false;
      } else {
        uploadButtonDisabled = true;
        alert(
          "所定の形式の出荷データファイル（拡張子は.csvか.xlsx）を選択してください。",
        );
      }
    }
  }

  $: if (selected !== previousSelected) {
    doFiltering();
  }

  const doFiltering = async () => {
    progressBarDisplay = "block";
    await deleteTableComponent();
    noDataCommentDisplay = "none";
    switch (selected) {
      case "全て表示":
        results.forEach((result) => {
          result.display = true;
        });
        break;
      case "正常のみ表示":
        results.forEach((result) => {
          if (!result.violations) {
            result.display = true;
          } else {
            result.display = false;
          }
        });
        break;
      case "エラーのみ表示":
        results.forEach((result) => {
          if (result.violations) {
            result.display = true;
          } else {
            result.display = false;
          }
        });
        break;
    }
    if (results.filter((result) => result.display).length === 0) {
      noDataCommentDisplay = "block";
    } else {
      await createTableComponent();
    }
    progressBarDisplay = "none";
    previousSelected = selected;
  };

  function deleteTableComponent() {
    dataTable = null;
  }

  function createTableComponent() {
    dataTable = DataTable;
  }

  function displayMessageDialog(title, message) {
    dialogTitle = title;
    dialogMessage = message;
    dialogComponent = MessageDialog;
  }

  function formatChange() {
    if (files) {
      uploadButtonDisabled = false;
    }
  }

  const execUploadApi = async () => {
    let uploadConfig = {
      withHeader: withHeader,
      charset: userContext.ecSettings.csvUploadCharset,
      schemaType: Number(formatType),
    };
    if (fileType === "csv") {
      uploadConfig.fileType = 0;
    } else if (fileType === "xlsx") {
      uploadConfig.fileType = 1;
    }
    let blob = new Blob([JSON.stringify(uploadConfig)], {
      type: "application/json",
    });
    let body = new FormData();
    body.append("csv", readedFile);
    body.append("uploadConfig", blob);

    return await backendApi.uploadShipmentsCsv(body);
  };

  const upload = loadingProgress.wrapAsync(async () => {
    hideCaptionText();
    dataTable = null;
    selected = "全て表示";
    previousSelected = "全て表示";
    noDataCommentDisplay = "none";
    try {
      try {
        results = await execUploadApi();
        if (!results || results.length === 0) {
          throw new HandledError(
            $_("errors._.title"),
            $_("errors.emptyShippingDataCsv"),
          );
        }
      } catch (error) {
        /** @type {import("~/libs/backendApi").ErrorResponse} */
        const errorResponse = error["errorResponse"];

        switch (errorResponse?.title) {
          case "tooLargeFile":
            throw new HandledError(
              $_("errors.tooLargeFile.title"),
              $_("errors.tooLargeFile.message"),
            );
          case "invalidParam": {
            const responseData = error["dataResponse"]; // なぜかdataプロパティを返す設計なので取り出し
            errorResponse.details.violations.forEach((violation) => {
              if (
                violation.path == "cashOnDeliveryAmount" &&
                !userContext.ecSettings.supportCashOnDelivery
              ) {
                throw new HandledError(
                  $_("errors.cashOnDeliveryIsNotSupported.title"),
                  $_("errors.cashOnDeliveryIsNotSupported.message"),
                );
              }

              if (responseData[violation.index].violations) {
                if (
                  responseData[violation.index].violations.get(violation.path)
                ) {
                  // すでに同じ行＆項目のエラーがある場合はメッセージを追加
                  const messageMap = responseData[
                    violation.index
                  ].violations.get(violation.path);
                  messageMap.push({
                    level: violation.level,
                    message: violation.message,
                  });
                } else {
                  // すでに同じ行のエラーがある場合は項目とメッセージを追加
                  responseData[violation.index].violations.set(
                    violation.path,
                    Array({
                      level: violation.level,
                      message: violation.message,
                    }),
                  );
                }
              } else {
                // まだ同じ行のエラーがない場合は新規追加
                const violations = new Map();
                violations.set(
                  violation.path,
                  Array({
                    level: violation.level,
                    message: violation.message,
                  }),
                );
                responseData[violation.index].violations = violations;
              }
            });
            results = responseData;
            break;
          }
          default:
            throw error;
        }
      }

      results.forEach((result, index) => {
        result["index"] = index;
        result["display"] = true;
      });

      noResultsCommentDisplay = "none";
      dataTable = DataTable;
      datatableDisplay = "block";
      uploadButtonDisabled = true;
      toast.info($_("pages.Upload.uploadComplete"));
    } catch (error) {
      showErrorMessage(error);
    }
  });

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      displayMessageDialog(error.title, error.message);
    } else {
      if (error instanceof HTTPError && error.response?.status == 401) {
        displayMessageDialog(
          $_("errors.unauthorized.title"),
          $_("errors.unauthorized.message"),
        );
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        displayMessageDialog(
          $_("errors.forbidden.title"),
          $_("errors.forbidden.message"),
        );
      } else {
        console.error(error);
        displayMessageDialog(
          $_("errors.defaultMessage.title"),
          $_("errors.defaultMessage.message"),
        );
      }
    }
  }

  /**
   * ダウンロードする荷物情報の一覧を作成する。
   * エラーを含む荷物情報がある場合はnullを返す。
   * @returns {Array} ダウンロードする荷物情報の一覧
   */
  function createTargetList() {
    let includeError = false;
    for (let i = 0; i < results.length; i++) {
      if (results[i].violations) {
        for (let key of results[i].violations.keys()) {
          results[i].violations.get(key).forEach((violation) => {
            if (violation.level === "ERROR") {
              // エラーを含んでいる場合はダウンロードさせない
              includeError = true;
            }
          });
          if (includeError) {
            return null;
          }
        }
      }
    }

    const targetList = JSON.parse(JSON.stringify(results));
    for (let i = 0; i < targetList.length; i++) {
      delete targetList[i]["index"];
      delete targetList[i]["display"];
      delete targetList[i]["violations"];

      // 空文字が渡される可能性のある項目のうちreciverAddress2、shipperAddress2以外について、空文字だったらプロパティを削除する。
      if (targetList[i]["customerOrderId"] === "") {
        delete targetList[i]["customerOrderId"];
      }
      if (targetList[i]["trackingNumber"] === "") {
        delete targetList[i]["trackingNumber"];
      }
      if (targetList[i]["shipperRemarks1"] === "") {
        delete targetList[i]["shipperRemarks1"];
      }
      if (targetList[i]["shipperRemarks2"] === "") {
        delete targetList[i]["shipperRemarks2"];
      }
      if (targetList[i]["desiredDate"] === "") {
        delete targetList[i]["desiredDate"];
      }
      if (targetList[i]["desiredTime"] === "") {
        delete targetList[i]["desiredTime"];
      }
      if (targetList[i]["packageDropPlace"] === "") {
        delete targetList[i]["packageDropPlace"];
      }
      if (targetList[i]["cashOnDeliveryAmount"] === "") {
        delete targetList[i]["cashOnDeliveryAmount"];
      }
      if (targetList[i]["numberOfPackages"] === "") {
        delete targetList[i]["numberOfPackages"];
      }
      if (targetList[i]["deliveryPrecautions"] === "") {
        delete targetList[i]["deliveryPrecautions"];
      }
    }
    return targetList;
  }

  /**
   * @returns {Promise<{shippingUnitId: string}>}
   */
  const execShipmentsApi = async () => {
    let body = {
      releasedAt: releasedAtUTC,
      shipments: downloadList,
    };
    return await backendApi.registShipmentInfo(body);
  };

  /**
   * @returns {Promise<{url: string}>}
   */
  const execDownloadShippingLabelApi = async () => {
    let downloadConfig = {
      withHeader: true,
      clientSpecifiedCharset:
        userContext.ecSettings?.csvUploadCharset ?? "Windows-31J",
    };
    let body = {
      downloadConfig: downloadConfig,
    };
    return await backendApi.downloadShipmentPdfAndCsv(shippingUnitId, body);
  };

  function fullDownloadComplete() {
    fileName = "ファイルが選択されていません。";
    results = [];
    datatableDisplay = "none";
    noResultsCommentDisplay = "block";
    uploadButtonDisabled = true;
    selected = "全て表示";
  }

  const download = async () => {
    progressBarDisplay = "block";
    downloadButtonDisabled = true;
    try {
      let download = document.createElement("a");
      download.href = downloadUrl;
      download.download = "";
      download.click();

      if (results.length === 0) {
        fullDownloadComplete();
      }
    } catch (error) {
      if (error instanceof HTTPError && error.response?.status == 400) {
        displayMessageDialog(
          $_("errors.errorContaining.title"),
          $_("errors.errorContaining.message"),
        );
      } else {
        showErrorMessage(error);
      }
    } finally {
      progressBarDisplay = "none";
      downloadButtonDisabled = false;
    }
  };

  /**
   * CSVファイルとしてダウンロードする荷物情報の一覧を発行する
   */
  const registerDownloadList = loadingProgress.wrapAsync(async () => {
    hideCaptionText();
    progressBarDisplay = "block";

    const now = new Date();
    releasedAtUTC = convertReleasedAtUTC(now);
    downloadList = createTargetList();

    try {
      if (downloadList !== null) {
        let responseData = await execShipmentsApi();
        if (!responseData) {
          throw new HandledError(
            $_("errors.errorContaining.title"),
            $_("errors.errorContaining.message"),
          );
        }
        shippingUnitId = responseData.shippingUnitId;

        if (!userContext.ecSettings.doesNotGenerateShippingLabel) {
          let message =
            downloadList.length +
            "件の送り状データを発行しています<br />数十秒かかる場合があります";
          loadingProgress.updateMessage(message);

          let responseData2 = await execDownloadShippingLabelApi();
          if (!responseData2) {
            throw new HandledError(
              $_("errors.errorContaining.title"),
              $_("errors.errorContaining.message"),
            );
          }
          downloadUrl = responseData2.url;
        }

        registerFinished = true;
      } else {
        displayMessageDialog(
          $_("errors.errorContaining.title"),
          $_("errors.errorContaining.message"),
        );
      }
    } catch (error) {
      showErrorMessage(error);
    }

    progressBarDisplay = "none";
  });

  /**
   * 発行した荷物情報の一覧、ファイルの選択状況をクリアする
   */
  function clearRegisterd() {
    if (
      window.confirm(
        `取り込み結果一覧に表示されているデータにはアクセスできなくなります。すべてクリアして新しいデータから${userContext.ecSettings.doesNotGenerateShippingLabel ? "登録を開始" : "送り状を発行"}してよろしいですか？`,
      )
    ) {
      resetDisplay();
    }
  }

  /**
   * Captionのメッセージを非表示にする
   */
  function hideCaptionText() {
    deleteCompleted = false;
  }

  /**
   * 同じファイルを連続で選択できるようにvalueを初期化する
   * @param {Event} event
   */
  function handleFileClick(event) {
    /** @type {HTMLInputElement} */ (event.target).value = null;
  }

  /**
   * 出荷データの取り込み後に発行の取り消しを行う
   */
  function disableShippingUnit() {
    if (
      window.confirm(
        `${
          userContext.ecSettings.doesNotGenerateShippingLabel ? "登録" : "発行"
        }の取り消しを行ったデータは、配送情報閲覧で表示されなくなります。よろしいですか？`,
      )
    ) {
      resetDisplay();
      let shippingUnitId = "";
      let ecId = userContext.loginUser?.currentCompanyId;
      shippingUnitId += "M" + "-" + ecId + "-" + releasedAtUTC;
      execDisableShippingUnitApi(shippingUnitId);
    }
  }

  /**
   * 発行を取り消す出荷番号をBEに渡す
   * @param {string} shippingUnitId
   */
  const execDisableShippingUnitApi = async (shippingUnitId) => {
    try {
      let body = {
        disabled: true,
      };
      await backendApi.disableShippingUnit(body, shippingUnitId);
    } catch (error) {
      if (
        error instanceof ErrorResponseException &&
        error.errorResponse.title === "alreadyInTransit"
      ) {
        displayMessageDialog(
          $_("errors.alreadyInTransit.title"),
          $_("errors.alreadyInTransit.message"),
        );
      } else {
        showErrorMessage(error);
      }
    }
  };

  /**
   * 現在の日時をISO8601基本形式UTCの形式にフォーマットして返す
   * @param {Date} now 生成されたDateオブジェクトのインスタンス
   * @returns {string} 現在の日時(UTC) yyyyMMddTHHmmssZ
   */
  function convertReleasedAtUTC(now) {
    const isoString = now.toISOString();
    const formattedDate = isoString.replace(/[-:.]/g, "").slice(0, -4) + "Z";
    return formattedDate;
  }

  /**
   * 画面の表示を初期状態に戻す
   */
  function resetDisplay() {
    hideCaptionText();
    dataTable = null;
    registerFinished = false;
    results = [];
    noResultsCommentDisplay = "block";
    datatableDisplay = "none";
    fileName = "ファイルが選択されていません。";
    uploadButtonDisabled = true;
  }

  /**
   * フォントがインストールされているかどうかを判定する
   * @param {string} fontName フォント名
   * @returns {boolean} フォントがインストールされているかどうか
   */
  function isInstalledFont(fontName) {
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");
    const text = "abcdefghijklmnopqrstuvwxyz0123456789";

    canvasContext.font = "72px monospace";
    const width1 = canvasContext.measureText(text).width;

    canvasContext.font = "72px '" + fontName + "', monospace";
    const width2 = canvasContext.measureText(text).width;

    return width2 !== width1;
  }
</script>

<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />
{#if !userContext.ecSettings.doesNotGenerateShippingLabel && !isInstalledFont("Noto Sans CJK JP")}
  <!-- 送り状発行有りのEC、かつフォント未インストールの場合のみ表示 -->
  <div class="alertInstallNotoFont">
    <span class="material-icons md-36">warning_amber</span>
    <p>
      送り状のPDFファイルを表示・印刷するためにフォントのインストールが必要です。<br
      />
      <a
        href="https://manual.oitoc.delivery/ec-admin-guide/oitoc-fonts-for-pdf.zip"
        >こちら</a
      >からフォントをダウンロードのうえインストールしてください。
    </p>
  </div>
{/if}

<div class="businessArea">
  {#if !registerFinished}
    <div class="uploadArea">
      <div class="item">
        <div class="itemName">取込みフォーマット</div>
        <div>
          <select
            id="format"
            name="format"
            bind:value={formatType}
            size="1"
            on:change={formatChange}
          >
            <option value="0">基本フォーマット(置ToC形式)</option>
            <option value="2">ANK EXPRESSフォーマット</option>
            <option value="1" disabled
              >B2クラウド(ヤマト運輸)フォーマット</option
            >
          </select>
        </div>
        {#if formatType === "0"}
          <div class="shippingDataTemplate">
            <a
              href="https://manual.oitoc.delivery/ec-admin-guide/oitoc-shipping-data.xlsx"
              >基本フォーマット(置ToC形式)のExcelファイルテンプレート</a
            >
          </div>
        {/if}
      </div>
      <div class="item">
        <div class="itemName">対象ファイル</div>
        <div>
          <input type="text" id="fileName" disabled value={fileName} />
        </div>
        <label class="fileUploadBtn">
          <input
            type="file"
            name="file"
            id="file"
            accept=".csv, .xlsx"
            bind:files
            on:click={handleFileClick}
          />ファイルを選択
        </label>
      </div>
      <div class="item">
        <div class="itemName">ヘッダ行有無</div>
        <div>
          <Switch
            bind:checked={withHeader}
            color="secondary"
            icons={false}
            on:SMUISwitch:change={formatChange}
          />
          <span style="font-size:smaller"
            >{withHeader ? "ヘッダ行有り" : "ヘッダ行無し"}</span
          >
          <label
            class="fileReadBtn"
            class:fileReadBtnWithCompanyName={userContext.loginUser
              .switchableCompanies?.length > 1}
            class:disabledButton={uploadButtonDisabled}
          >
            <input type="button" id="file" on:click={upload} />
            {#if userContext.loginUser.switchableCompanies?.length > 1}
              <span class="buttonText1"
                >{userContext.getCurrentCompanyName()}の<br
                />出荷データを取込み</span
              >
            {:else}
              <span class="buttonText2">取込み開始</span>
            {/if}
          </label>
        </div>
      </div>
    </div>
  {:else}
    <div style="height: 15px;" />
    <p class="captionText">
      出荷データの取り込みが完了しました。（発行日時:{formatUtcToJst(
        releasedAtUTC,
        "yyyy/MM/dd(E) HH:mm",
        { locale: localeJa },
      )}）
      {#if !userContext.ecSettings.doesNotGenerateShippingLabel}
        <br
        />送り状印刷のためのデータは引き続きダウンロードボタンより取得できます。
      {/if}
    </p>
    <div class="registerdButtonArea">
      <label class="clearBtn">
        <input
          type="button"
          id="file"
          on:click={clearRegisterd}
        />クリア後に新しい取り込みを行う
      </label>
      <label class="cancelBtn">
        <input
          type="button"
          id="file"
          on:click={disableShippingUnit}
        />{userContext.ecSettings.doesNotGenerateShippingLabel
          ? "登録"
          : "発行"}の取り消しを行う
      </label>
    </div>
  {/if}
  <div style="height: 15px;" />
  <div class="captionContainer">
    <div class="captionArea">取込み結果一覧</div>
    {#if deleteCompleted}
      <p class="captionText">選択された取込みデータの削除が完了しました。</p>
    {/if}
  </div>
  <div class="resultArea">
    <div id="noResultsComment" style="display:{noResultsCommentDisplay}">
      出荷データ取込み後に結果が表示されます。
    </div>
    <div id="progressBar" style="display:{progressBarDisplay}">
      <LinearProgress indeterminate />
    </div>
    <div id="datatable" style="display: {datatableDisplay};">
      {#if !registerFinished || !userContext.ecSettings.doesNotGenerateShippingLabel}
        <div class="toolsArea">
          {#if !registerFinished}
            <Set chips={choices} let:chip choice bind:selected>
              <Chip {chip}>
                <Text
                  >{numberOfRecords
                    ? `${chip} (${numberOfRecords[chip]}件)`
                    : chip}</Text
                ></Chip
              >
            </Set>
          {/if}
          <div class="topButtonArea">
            {#if !registerFinished}
              <label
                class="downloadBtn"
                class:disabledButton={downloadButtonDisabled}
              >
                <input
                  type="button"
                  id="file"
                  on:click={registerDownloadList}
                />{userContext.ecSettings.doesNotGenerateShippingLabel
                  ? "登録"
                  : "発行"}
              </label>
            {:else}
              <label class="downloadBtn">
                <input
                  type="button"
                  id="file"
                  on:click={download}
                />ダウンロード
              </label>
            {/if}
          </div>
        </div>
      {/if}
      <div id="noDataComment" style="display:{noDataCommentDisplay}">
        該当するデータがありません。
      </div>
      <svelte:component
        this={dataTable}
        bind:results
        {registerFinished}
        {countingNumberOfRecords}
        {supportCashOnDelivery}
        {formatType}
      />
    </div>
  </div>
</div>

<style lang="scss">
  .alertInstallNotoFont {
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    margin: 15px 0;
    padding: 10px 15px;
    background-color: #fff;
    border: 1px solid #ff9800;
    border-radius: 4px;
    line-height: 1.43;
    font-size: 0.875rem;

    .material-icons {
      color: #ff9800;
    }
  }

  .businessArea {
    display: columns;
  }
  .captionContainer {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .captionArea {
    background-color: #064491cb;
    width: 180px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }
  .captionText {
    font-size: 15px;
    color: #064491cb;
    font-weight: bold;
    line-height: 1.3;
  }
  .uploadArea {
    width: 758px;
    height: 137px;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 10px 15px;
    display: columns;
  }
  .item {
    display: flex;
    position: relative;
    width: 100%;
    margin: 2px 0;

    .shippingDataTemplate {
      display: flex;
      align-items: center;
      width: 180px;

      a {
        font-size: 0.75em;
        color: var(--mdc-theme-secondary);
      }
    }
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 150px;
    height: 28px;
    padding-top: 15px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  #file {
    position: absolute;
    opacity: 0;
    width: 10px;
  }
  .fileUploadBtn {
    width: 180px;
    height: 27px;
    padding-top: 15px;
    text-align: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
  .fileUploadBtn:hover,
  .fileUploadBtn:focus,
  .fileReadBtn:hover,
  .fileReadBtn:focus,
  .clearBtn:hover,
  .clearBtn:focus,
  .cancelBtn:hover,
  .cancelBtn:focus,
  .downloadBtn:hover,
  .downloadBtn:focus {
    filter: brightness(1.2);
  }
  .disabledButton {
    filter: brightness(0.6);
    pointer-events: none;
  }
  #format {
    margin: 4px 10px;
    height: 35px;
    width: 408px;
  }
  #fileName {
    margin: 2px 10px;
    height: 33px;
    width: 400px;
  }
  .fileReadBtn {
    position: absolute;
    right: 0%;
    width: 180px;
    height: 27px;
    padding-top: 15px;
    text-align: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
  .fileReadBtnWithCompanyName {
    padding: 7px 0 9px;
    line-height: 1.1;
  }
  .registerdButtonArea {
    display: flex;
    gap: 6px;
    margin: 8px 0px 16px 0px;
  }
  .clearBtn {
    padding: 15px 8px 15px 8px;
    text-align: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
  .cancelBtn {
    padding: 15px 20px 15px 20px;
    text-align: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
  .resultArea {
    width: calc(100% - 12px);
    min-width: 778px;
    height: fit-content;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }
  #noResultsComment {
    margin: 10px;
    font-size: smaller;
  }
  #progressBar {
    margin: 10px;
  }
  #noDataComment {
    margin: 10px;
    font-size: smaller;
  }
  .toolsArea {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 4px;
    height: 60px;
  }
  .topButtonArea {
    display: flex;
  }
  .topButtonArea .downloadBtn {
    position: absolute;
    right: -16px;
    top: 8px;
    transform: translateX(-15%);
    width: 180px;
    height: 27px;
    padding-top: 15px;
    text-align: center;
    background: #369;
    color: #fff;
    cursor: pointer;
    font-size: smaller;
    border-radius: 8px 8px 8px 8px;
  }
</style>
