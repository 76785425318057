<script>
  import { getContext } from "svelte";
  import { push } from "svelte-spa-router";

  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import EcSwitchableInfo from "~/pages/Main/EcSwitchableInfo.svelte";
  import ConfirmedUpload from "~/pages/Upload/ConfirmedUpload.svelte";
  import PreUpload from "~/pages/Upload/PreUpload.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  const SUBJECT = userContext.hasEcAdminRole()
    ? "出荷データ登録"
    : "荷受けデータ登録";
  const PRE_UPLOAD = "事前出荷データ登録";
  const CONFIRMED_UPLOAD = userContext.hasEcAdminRole()
    ? "出荷確定登録"
    : "事前荷受け登録";

  /** 選択可能なメニュー一覧 @type {Array<PRE_UPLOAD | CONFIRMED_UPLOAD>} */
  const selectableMenu =
    userContext.hasEcAdminRole() && !userContext.hasAnkAdminRole()
      ? [PRE_UPLOAD]
      : [CONFIRMED_UPLOAD];

  /** 切替え可能なEC事業者名 @type {string} */
  let switchableEcName;

  /** 選択中のメニュー @type {PRE_UPLOAD | CONFIRMED_UPLOAD} */
  let active = selectableMenu[0];

  /** メニュー切替え可能な場合にtrue @type {boolean} */
  let switchable = true;
</script>

<div class="wrapper">
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">{SUBJECT}</h1>
  </div>
  {#if userContext.loginUser.switchableCompanies?.length > 1}
    <EcSwitchableInfo bind:switchableEcName>
      <p slot="description">
        {#if userContext.hasAnkAdminRole()}
          事前データを登録する場合は {switchableEcName} ユーザーに切り替えてください。
        {:else}
          出荷確定データを登録する場合は ANK EXPRESS
          ユーザーに切り替えてください。
        {/if}
      </p>
    </EcSwitchableInfo>
  {/if}
  {#if switchable}
    <div class="captionTabs">
      {#each selectableMenu as menu}
        <input
          type="radio"
          name="selectableMenu"
          id={menu}
          bind:group={active}
          value={menu}
        />
        <label
          for={menu}
          class="selectableMenu"
          class:hoverEffect={selectableMenu.length > 1}>{menu}</label
        >
      {/each}
    </div>
  {/if}
  {#if active === PRE_UPLOAD}
    <PreUpload bind:switchable />
  {:else if active === CONFIRMED_UPLOAD}
    <ConfirmedUpload />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }
  .titleArea {
    height: 40px;
    display: flex;
    .titleLine {
      background-color: #064491cb;
      min-width: 15px;
      height: 40px;
      border-radius: 0px 0px 0px 0px;
    }
    .title {
      display: flex;
      font-size: x-large;
      margin: auto auto auto 10px;
      min-width: 500px;
    }
  }
  .captionTabs {
    margin-top: 15px;
    display: flex;
    justify-content: start;
    .selectableMenu {
      background-color: #0645917e;
      width: fit-content;
      min-width: fit-content;
      padding-right: 18px;
      height: 26px;
      border-radius: 10px 10px 0px 0px;
      padding-top: 12px;
      padding-left: 20px;
      color: #fff;
      font-weight: 900;
    }
    input[name="selectableMenu"] {
      display: none;
    }
    input:checked + .selectableMenu {
      background-color: #064491cb;
    }
    .hoverEffect:hover {
      filter: brightness(1.2);
    }
  }
</style>
