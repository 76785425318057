<script>
  import Button from "@smui/button";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import MessageDialog from "~/components/MessageDialog.svelte";
  import backendApi from "~/libs/backendApi";
  import { HandledError } from "~/libs/commonTypes";
  import {
    CONTEXT_KEY_USER,
    USER_KINDS_FOR_CONTRACT,
    USER_KINDS_FOR_PARTNER,
  } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import {
    activeContent,
    managementAddUserClose,
    managementForNewUserClose,
    messageDialogClose,
    needReload,
  } from "~/libs/stores";
  import EcSwitchableInfo from "~/pages/Main/EcSwitchableInfo.svelte";
  import ManagementAddUser from "~/pages/Management/ManagementAddUser.svelte";
  import ManagementForNewUser from "~/pages/Management/ManagementForNewUser.svelte";
  import ManagementResult from "~/pages/Management/ManagementResult.svelte";
  import {
    getCompanyNameOptionGroups,
    getUserKindsInfo,
  } from "~/pages/Management/managementUtils";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);
  if (userContext.needsLogin()) {
    push("/");
  }

  /** @type {import("svelte/store").Unsubscriber} */
  let managementAddUserCloseUnsubscriber;
  /** @type {import("svelte/store").Unsubscriber} */
  let managementForNewUserCloseUnsubscriber;
  /** @type {import("svelte/store").Unsubscriber} */
  let messageDialogCloseUnsubscriber;
  /** @type {import("svelte/store").Unsubscriber} */
  let needReloadUnsubscriber;

  const FIRST = false;
  const AGAIN = true;

  /** @type {import("~/libs/backendApi").GetCompaniesResponse}>} */
  let companyNameList = [];
  let userKindsList = [];
  let companyNameValue;
  let regulationCompanyName;
  let regulationUserKind;
  let managementForNewUser;
  let userKindsValue = "";
  let userIdValue = "";
  let displayNameValue = "";
  let errorMsgDisplay = "none";
  let noResultsCommentDisplay = "block";
  let noDataCommentDisplay = "none";
  /** @type {Array<import("~/libs/commonTypes").CustomedUserInfo>} */
  let results = [];
  let count;
  let userKindsDisabled = true;
  let displayingAddUser = false;
  let displayingForNewUser = false;
  let managementAddUser;
  let managementResult;
  let dialogComponent;
  let dialogTitle;
  let dialogMessage;
  let searchedBody;
  let addedLoginData = { userName: "", password: "" };
  let goHome = false;
  let result;

  /** 会社名の選択肢 */
  let companyNameOptionGroups = [];

  /** 切替え可能なEC事業者名 @type {string} */
  let switchableEcName;

  onDestroy(() => {
    managementAddUserCloseUnsubscriber?.();
    managementForNewUserCloseUnsubscriber?.();
    messageDialogCloseUnsubscriber?.();
    needReloadUnsubscriber?.();
  });

  const getCompaniesNameList = async () => {
    companyNameList = [];
    try {
      companyNameList = await backendApi.getCompanies();
      if (
        userContext.hasContractAdminRole() ||
        userContext.hasSccOperatorRole() ||
        userContext.hasTrumpAdminRole() // FIXME: 暫定でトランプ管理者が他事業者のユーザーを管理できるようにする
      ) {
        userKindsList = USER_KINDS_FOR_CONTRACT;
        companyNameOptionGroups = getCompanyNameOptionGroups(companyNameList);
        if (companyNameList.length == 1) {
          companyNameValue = companyNameList[0].id;
          userKindsDisabled = false;
        }
      } else if (userContext.hasShippingPartnerAdminRole()) {
        companyNameValue = companyNameList[0].id;
        regulationCompanyName = companyNameList[0].name;
        userKindsList = USER_KINDS_FOR_PARTNER;
        userKindsDisabled = false;
      } else if (userContext.hasEcAdminRole()) {
        companyNameValue = companyNameList[0].id;
        regulationCompanyName = companyNameList[0].name;
        userKindsValue = "ec-admin";
        regulationUserKind = "EC事業者／管理者";
      } else {
        throw new Error();
      }
    } catch (error) {
      goHome = true;
      showErrorMessage(error);
    }
  };

  /**
   * エラーメッセージをダイアログで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorMessage(error) {
    if (error instanceof HandledError) {
      displayMessageDialog(error.title, error.message);
    } else {
      if (error instanceof HTTPError && error.response?.status == 400) {
        displayMessageDialog(
          $_("errors.searchConditionError.title"),
          $_("errors.searchConditionError.message"),
        );
      } else if (error instanceof HTTPError && error.response?.status == 401) {
        displayMessageDialog(
          $_("errors.unauthorized.title"),
          $_("errors.unauthorized.message"),
        );
      } else if (error instanceof HTTPError && error.response?.status == 403) {
        displayMessageDialog(
          $_("errors.forbidden.title"),
          $_("errors.forbidden.message"),
        );
      } else {
        console.error(error);
        displayMessageDialog(
          $_("errors.defaultMessage.title"),
          $_("errors.defaultMessage.message"),
        );
      }
    }
  }

  onMount(getCompaniesNameList);

  function openForNewUser() {
    displayingForNewUser = true;
    managementForNewUser = ManagementForNewUser;
  }

  function handlePostData(event) {
    addedLoginData = event.detail;
  }

  const clearInput = () => {
    if (userContext.hasContractAdminRole()) {
      companyNameValue = "";
      userKindsValue = "";
      userIdValue = "";
      displayNameValue = "";
    } else {
      userKindsValue = "";
      userIdValue = "";
      displayNameValue = "";
    }
  };

  async function openWindow() {
    displayingAddUser = true;
    managementAddUser = null;
    managementAddUser = await ManagementAddUser;
  }

  managementAddUserCloseUnsubscriber = managementAddUserClose.subscribe(
    (isClosed) => {
      if (displayingAddUser && isClosed) {
        displayingAddUser = false;
        managementAddUser = null;
        managementAddUserClose.set(false);
        if (
          result == "success" &&
          addedLoginData.userName != "" &&
          addedLoginData.password != ""
        ) {
          openForNewUser();
        } else if (result == "failed") {
          dialogComponent = MessageDialog;
        }
        // 検索済みの場合は表を更新する
        if (results.length > 0) {
          managementResult = null;
          reload();
        }
        result = null;
      }
    },
  );

  managementForNewUserCloseUnsubscriber = managementForNewUserClose.subscribe(
    (isClosed) => {
      if (displayingForNewUser && isClosed) {
        addedLoginData = { userName: "", password: "" };
        displayingForNewUser = false;
        managementForNewUser = null;
        managementForNewUserClose.set(false);
      }
    },
  );

  messageDialogCloseUnsubscriber = messageDialogClose.subscribe(() => {
    dialogTitle = null;
    dialogMessage = null;
    dialogComponent = null;
    messageDialogClose.set(false);
    if (goHome) {
      goHome = false;
      activeContent.set("Home");
    }
  });

  function displayMessageDialog(title, message) {
    dialogTitle = title;
    dialogMessage = message;
    dialogComponent = MessageDialog;
  }

  function assertInputData() {
    if (companyNameValue === "" || userKindsValue === "") {
      throw new HandledError();
    }
  }

  const execUsersSearchApi = async (searchingAgain) => {
    let body;
    if (searchingAgain) {
      body = searchedBody;
    } else {
      body = {
        companyId: companyNameValue,
        role: userKindsValue,
      };
      if (displayNameValue) {
        body["displayName"] = displayNameValue;
      }
      if (userIdValue) {
        body["userName"] = userIdValue;
      }
    }

    const response = await backendApi.searchUsers(body);
    searchedBody = body;
    return response;
  };

  const search = loadingProgress.wrapAsync(async () => {
    managementResult = null;
    errorMsgDisplay = "none";
    noDataCommentDisplay = "none";

    try {
      assertInputData();
      ({ users: results, count } = await execUsersSearchApi(FIRST));
      // 画面のちらつき防止のために0.3秒待つ
      await sleep(300);
      if (results.length > 0) {
        results.forEach((result) => {
          let matchCompany = companyNameList.find(
            (company) => company.id === result.companyId,
          );
          result["companyName"] = matchCompany.name;
        });
        count = count;
        results = results;
        managementResult = ManagementResult;
      } else {
        noDataCommentDisplay = "block";
      }
    } catch (error) {
      noDataCommentDisplay = "block";
      if (error instanceof HandledError) {
        errorMsgDisplay = "block";
      } else {
        showErrorMessage(error);
      }
    } finally {
      noResultsCommentDisplay = "none";
    }
  });

  const reload = loadingProgress.wrapAsync(async () => {
    errorMsgDisplay = "none";
    noDataCommentDisplay = "none";

    try {
      ({ users: results, count } = await execUsersSearchApi(AGAIN));
      // 画面のちらつき防止のために0.3秒待つ
      await sleep(300);

      if (results.length > 0) {
        results.forEach((result) => {
          let matchCompany = companyNameList.find(
            (company) => company.id === result.companyId,
          );
          result["companyName"] = matchCompany.name;
        });
        managementResult = ManagementResult;
      } else {
        noDataCommentDisplay = "block";
        managementResult = null;
      }
    } catch (error) {
      noDataCommentDisplay = "block";
      managementResult = null;
      showErrorMessage(error);
    } finally {
      noResultsCommentDisplay = "none";
    }
  });

  needReloadUnsubscriber = needReload.subscribe((isNeed) => {
    if (isNeed && searchedBody) {
      reload();
      needReload.set(false);
    }
  });

  function handleMessage(event) {
    result = event.detail.result;
    dialogTitle = event.detail.title;
    dialogMessage = event.detail.message;
  }

  function handleUserKindsList(event) {
    [userKindsValue, userKindsList, userKindsDisabled, regulationUserKind] =
      getUserKindsInfo(Number(event.target.value), companyNameList);
  }
</script>

<svelte:component this={dialogComponent} {dialogTitle} {dialogMessage} />
<svelte:component
  this={managementAddUser}
  {companyNameList}
  on:postData={handlePostData}
  on:message={handleMessage}
/>
<svelte:component this={managementForNewUser} {addedLoginData} />
<div class="wrapper">
  <div class="titleArea">
    <div class="titleLine" />
    <h1 class="title">ユーザーの管理</h1>
  </div>
  {#if userContext.loginUser.switchableCompanies?.length > 1}
    <EcSwitchableInfo bind:switchableEcName>
      <p slot="description">
        {switchableEcName} のユーザー管理を行う場合はユーザーを切り替えてください。
      </p>
    </EcSwitchableInfo>
  {/if}
  <div class="businessArea">
    <div class="captionArea">検索条件</div>
    <div class="termsArea">
      <div class="errorArea" style="display: {errorMsgDisplay}">
        <p>※必須項目の検索条件を入力してください。</p>
      </div>
      <div class="itemColumn">
        <div class="item">
          <div class="itemName">
            会社名<span class="required">必須</span>
          </div>
          <div>
            {#if userContext.hasContractAdminRole() || userContext.hasSccOperatorRole() || userContext.hasTrumpAdminRole()}<!-- FIXME: 暫定でトランプ管理者が他事業者のユーザーを管理できるようにする -->
              <select
                name="companyName"
                id="companyName"
                bind:value={companyNameValue}
                on:change={handleUserKindsList}
              >
                <option value="" selected disabled>選択してください</option>
                {#each companyNameOptionGroups as [groupName, companyNames]}
                  {#if companyNames.length > 0}
                    <optgroup label={groupName}>
                      {#each companyNames as { id, name }}
                        <option value={id}>{name}</option>
                      {/each}
                    </optgroup>
                  {/if}
                {/each}
              </select>
            {:else}
              <input type="text" bind:value={regulationCompanyName} disabled />
            {/if}
          </div>
        </div>
        <div class="item secondItem">
          <div class="itemName">
            ユーザー種別<span class="required">必須</span>
          </div>
          <div>
            {#if userKindsValue === "ec-admin" || userKindsValue === "scc-operator"}
              <input type="text" bind:value={regulationUserKind} disabled />
            {:else}
              <select
                name="userKinds"
                id="userKinds"
                bind:value={userKindsValue}
                disabled={userKindsDisabled}
              >
                <option value="" selected disabled>選択してください</option>
                {#each userKindsList as { value, kind }}
                  <option {value}>{kind}</option>
                {/each}
              </select>
            {/if}
          </div>
        </div>
      </div>
      <div class="itemColumn">
        <div class="item">
          <div class="itemName">ユーザーID</div>
          <div>
            <input
              type="text"
              placeholder="0000/user-name"
              bind:value={userIdValue}
            />
          </div>
        </div>
        <div class="item secondItem">
          <div class="itemName">表示名</div>
          <div>
            <input
              type="text"
              placeholder="山田太郎"
              bind:value={displayNameValue}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttonArea">
    <Button
      style="color:rgba(255, 255, 255, 1); background-color:rgba(180, 208, 241, 1);"
      touch
      variant="unelevated"
      on:click={clearInput}
      >検索条件をクリア
    </Button>
    <Button touch variant="unelevated" on:click={search}
      >検&nbsp;&nbsp;索
    </Button>
  </div>
  {#if userContext.hasPermitUserManagementRole()}
    <div class="addButtonArea">
      <Button touch variant="unelevated" on:click={openWindow}
        >ユーザーを追加
      </Button>
    </div>
  {/if}
  <div class="resultWrapper">
    <div class="captionArea">検索結果一覧</div>
    <div class="resultArea">
      <div id="noResultsComment" style="display: {noResultsCommentDisplay}">
        検索後に結果が表示されます。
      </div>
      <div id="datatable">
        <div id="noDataComment" style="display:{noDataCommentDisplay}">
          該当するデータがありません。
        </div>
        <svelte:component
          this={managementResult}
          {results}
          {count}
          {companyNameList}
        />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: calc(100% - 40px);
    padding: 20px 20px 20px 20px;
    flex-direction: column;
  }
  .titleArea {
    height: 40px;
    display: flex;
  }
  .titleLine {
    background-color: #064491cb;
    min-width: 15px;
    height: 40px;
    border-radius: 0px 0px 0px 0px;
  }
  .title {
    display: flex;
    font-size: x-large;
    margin: auto auto auto 10px;
    min-width: 500px;
  }
  .businessArea {
    margin: 16px 0px 0px 0px;
    display: columns;
  }
  .captionArea {
    background-color: #064491cb;
    width: 180px;
    height: 26px;
    border-radius: 10px 10px 0px 0px;
    padding-top: 12px;
    padding-left: 20px;
    color: #fff;
    font-weight: 900;
  }
  .termsArea {
    width: 758px;
    height: auto;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0 5px 5px 5px;
    padding: 13px 15px 6px;
    display: columns;
  }
  .errorArea {
    color: red;
    font-weight: bold;
    font-size: smaller;
    margin-bottom: 6px;
  }
  .itemColumn {
    display: flex;
  }
  .item {
    display: flex;
    position: relative;
    width: 100%;
    height: 54px;
    margin: 0;
  }
  .secondItem {
    margin-left: 12px;
  }
  .itemName {
    background-color: #b4d0f1cb;
    width: 140px;
    height: 30px;
    margin: 0 10px 3px 0;
    padding-top: 17px;
    color: #242424;
    font-size: smaller;
    font-weight: 900;
    text-align: center;
  }
  .required {
    padding: 2px 4px;
    margin-left: 6px;
    color: #fff;
    background-color: #f90404;
  }
  #companyName,
  #userKinds {
    width: 220px;
    height: 46px;
  }
  input[type="text"] {
    width: 213px;
    height: 40px;
  }
  .buttonArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    padding: 15px 16px;
    width: 800px;

    :global(.mdc-button) {
      margin: 0 10px;
      height: 40px;
      width: 156px;
    }
  }
  .addButtonArea {
    width: 100%;
    min-width: 822px;
    text-align: right;

    :global(.mdc-button) {
      height: 40px;
      width: 156px;
      margin-right: 2px;
    }
  }
  .resultWrapper {
    margin-top: -40px;
  }
  .resultArea {
    width: calc(100% - 12px);
    min-width: 822px;
    height: fit-content;
    background-color: white;
    border: 1px solid #bdbdbdcb;
    border-radius: 0px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    display: columns;
  }
  #noResultsComment {
    margin: 10px;
    font-size: smaller;
  }
  #noDataComment {
    margin: 10px;
    font-size: smaller;
  }
</style>
