<svg
  id="_レイヤー_2"
  data-name="レイヤー_2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 50.4 63"
>
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #0051a5;
      }
    </style>
  </defs>
  <path
    class="cls-2"
    d="M6.3,0C2.84,0,.03,2.84.03,6.3l-.03,50.4c0,3.47,2.8,6.3,6.27,6.3h37.83c3.47,0,6.3-2.83,6.3-6.3V21.51c0-1.67-.66-3.28-1.86-4.44L33.33,1.86C32.16.66,30.55,0,28.89,0,28.89,0,6.3,0,6.3,0ZM28.35,18.9V4.73l17.32,17.32h-14.18c-1.73,0-3.15-1.42-3.15-3.15Z"
  />
  <rect class="cls-1" x="6.2" y="33.5" width="38" height="22" rx="3" ry="3" />
  <path
    class="cls-2"
    d="M15.32,40.43v1.33h-4.95v-1.33h4.95ZM11.88,40.58v7.19h-1.52v-7.19h1.52ZM15.47,46.45v1.33h-5.1v-1.33h5.1ZM15.07,43.45v1.18h-4.2v-1.18h4.2Z"
  />
  <path
    class="cls-2"
    d="M15.91,47.7l2.21-3.69-2.08-3.51.04-.08h1.63l1.07,1.85c.04.08.08.16.12.24.04.08.08.16.12.25.06.11.11.22.17.34h.04c.06-.12.11-.23.17-.34.04-.09.07-.17.12-.25.04-.08.08-.16.13-.24l1.07-1.85h1.63l.05.08-2.09,3.51,2.21,3.69-.04.08h-1.66l-.98-1.71c-.12-.23-.25-.47-.37-.71l-.22-.43h-.04l-.22.43c-.13.25-.25.49-.36.71l-.99,1.71h-1.66l-.04-.08Z"
  />
  <path
    class="cls-2"
    d="M23.46,41.93c.27-.55.67-.95,1.19-1.2.53-.26,1.18-.39,1.96-.39.37,0,.72.02,1.03.05.31.03.57.07.79.12v1.31l-.05.07c-.54-.1-1.06-.15-1.56-.15-.37,0-.69.03-.95.09-.26.06-.47.16-.63.28-.18.15-.32.38-.42.71-.1.33-.15.69-.15,1.08v.41c0,.43.05.81.16,1.14.11.34.24.57.4.69.17.14.39.23.65.28.27.04.56.07.87.07.26,0,.57-.02.91-.07.34-.05.63-.1.86-.16l.08.07v1.28c-.29.07-.59.14-.91.19-.32.06-.73.08-1.23.08-.79,0-1.44-.13-1.94-.4s-.87-.69-1.1-1.26c-.23-.57-.35-1.3-.35-2.18s.13-1.55.4-2.1Z"
  />
  <path
    class="cls-2"
    d="M34.58,40.43v1.33h-4.95v-1.33h4.95ZM31.14,40.58v7.19h-1.52v-7.19h1.52ZM34.73,46.45v1.33h-5.1v-1.33h5.1ZM34.33,43.45v1.18h-4.2v-1.18h4.2Z"
  />
  <path
    class="cls-2"
    d="M35.74,40.43h1.52v7.35h-1.52v-7.35ZM35.74,46.37h4.75v1.41h-4.75v-1.41Z"
  />
</svg>
